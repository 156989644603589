import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DefaultButton, Label } from 'office-ui-fabric-react';
import AzureConnector from '../Module/AzureConnector_Anon';
import Header from './Header';
import Footer from './Footer';
import Modal from 'react-modal';
import AnlageNeuerBenutzer from './AnlageNeuerBenutzer_Anon';
import WordCloud from './WordCloud_Anon';
import ReactLoading from 'react-loading';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/WordCloud.css');
// require('../css/AkademieStyles.css');


export interface IVeranstaltungsdetailsProps {
  No?: string;
  Description?: string;
  Description_2?: string;
  KeyWords?: any;
  Rubrik?: string;
  Texts?: any;
  Beschreibung?: string;
  Start_date?: string;
  End_date?: string;
  Time_from?: string;
  Time_to?: string;
  Date?: string;
  Max_Participant?: string;
  Min_Participant?: string;
  Participant_total?: string;
  Location_Name?: string;
  Location_City?: string;
  Location_Post_Code?: string;
  Location_Address?: string;
  Location_Full?: string;
  Amount_LCY_net?: string;
  Amount_LCY_net_Member?: string;
  Dokumente?: string;
  Zielgruppe?: string;
  HinweisFront?: string;
  Note_Front?: string;
  Leaders?: [{ No: string, Name: string, Bezeichnung_Name_Web: string }];
  Bezeichnung_Name_Web?: string;
  Programm?: string;
  Seminarziel?: string;
  Services?: [{ Amount_LCY_net: string, Mitglieder_Leistung: boolean }];
  Assistenz_Name?: string;
  Assistenz_Name_Email?: string;
  Contact_Person_Name?: string;
  Contact_Person_Email?: string;
}
export interface IVeranstaltungtProps {
  onChangeHeader:any
}
export default function Veranstaltungsdetails(props:IVeranstaltungtProps) {
  // const events1Json=require('../assets/json/events.json');
  const[result,setResult]=React.useState<IVeranstaltungsdetailsProps>(null);
  const[veranstaltungNoExpand,setVeranstaltungNoExpand]=React.useState(null);
  const [veranstaltung, setVeranstaltungBaseJson] = React.useState<IVeranstaltungsdetailsProps>(null);
  const history = useHistory();
  const location = useLocation();
  const [key, setKey] = React.useState(null);
  // const [aux, setAux] = React.useState(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [anlageNeuerBenutzerJsX, setAnlageNeuerBenutzerJsX] = React.useState<JSX.Element>(null);
  const [hinweisText, setHinweistext] = React.useState<JSX.Element>(null);
  // const [modHinweis, setModHinweis] = React.useState<boolean>(false);
  const [coloredDot, setColoredDot] = React.useState<JSX.Element>(<></>);
  const [ausgebuchtText, setAusgebuchtText] = React.useState<string>("");
  const [buchungsButton, setBuchungsButton] = React.useState(null);
  const [currMetaData, setcurrMetaData] = React.useState(null);
  let currShareUrl = window.location.href;

  let primaryCol: React.CSSProperties = {
    width: '70%'
  }

  let secondaryCol: React.CSSProperties = {
    width: '30%'
  }

  let tableStyle: React.CSSProperties = {
    borderSpacing: '10px',
    tableLayout: 'fixed',
    fontFamily: 'Segoe Ui',
    fontSize: '16px'
  }

  let baseTd: React.CSSProperties = {
    verticalAlign: 'top',
    paddingRight: '3%'
  }

  let baseTdRed: React.CSSProperties = {
    verticalAlign: 'top',
    paddingRight: '3%',
    color:'#c50023'
  }
  let titleDiv_Black: React.CSSProperties = {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '2%',
    borderSpacing: '20px 0',
    marginTop: '5%'
  }
  React.useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.has('key')) {
      setKey(search.get('key'));
    }
    setShowModal(false);
  }, [location]);

  React.useEffect(() => {
    if (veranstaltung && veranstaltung.HinweisFront !== "") {
      setHinweistext(<div className='subCols'>
        <div className='titleDiv'>Hinweis</div>
        <div>
          {veranstaltung.HinweisFront}
        </div>
      </div>);
    }
  }, [veranstaltung]);

  React.useEffect(() => {

    if (key == null) {
      const search = new URLSearchParams(location.search);
      if (search.has('key')) {
        setKey(search.get('key'));
      }
      // if (search.has('Aux')) {
      //   setAux(search.get('Aux'));
      // }
    }
    else {
      if(result===null)
      {
        var eventExistInJSON:boolean=false;
        // fetch('/assets/json/eventsTST.json').then((res) => res.json())

        //Details direkt abrufen wegen Abgebucht und das Interval für das Neue JSON POwer Automate
        fetch('/assets/json/eventsNoExpand.json').then((res) => res.json())
        .then((jsonEventsData) => {
          jsonEventsData.forEach((element:any)=>{
            if(element.No!=null&&element.No===key)
            {
              setVeranstaltungNoExpand(element);
              return false;
            }
        });
      });
        // fetch('/assets/json/events.json').then((res) => res.json())
        // .then((jsonEventsData) => {
        //   jsonEventsData.forEach((element:any)=>{
        //     if(element.KeyWords)
        //     {
        //       element.KeyWords.forEach((item:any)=>{
        //         if(item.No!=null&&item.No===key)
        //         {
        //           // result=element;
        //           eventExistInJSON=true;
        //           setResult(element);
        //           return false;
        //         }
        //       });
        //     }
        //   });
          if(!(eventExistInJSON))
          {
            var aux="78-258";
            const urlEndPart = "/nav/events/" + key + "/" + aux + "?expand=leaders,texts,keywords,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
            const azureCon = new AzureConnector();
      
            azureCon.get(urlEndPart)
            .then((result: any) => {
              if(result !== undefined)
              {
                setResult(result);
              }
            })
            .catch((error: any) => {
              console.log("get from NAV error");
              
            });
          }
        // });
      }else
        if(result!=null)
        {
          let tmpEvent: IVeranstaltungsdetailsProps = { Date: "",Location_Full:"",Rubrik : "",Beschreibung :""};
          tmpEvent=result;
          var dateSplit: any[] = result.Start_date.split("T")[0].split("-");
          var fullDate: string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
          tmpEvent.Date = fullDate;

          if (result.Start_date !== result.End_date) {
            dateSplit = result.End_date.split("T")[0].split("-");
            fullDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
            tmpEvent.Date = tmpEvent.Date + " - " + fullDate;
          }

          var fullLoca: string = result.Location_Name + ", " + result.Location_Address + ", " + result.Location_Post_Code + " " + result.Location_City;
          tmpEvent.Location_Full = fullLoca;
          var fullRubrik: string = "";
          for (var i: number = 0; i < result.KeyWords.length; i++) {
            if (i !== 0) {
              fullRubrik += ", ";
            }
            fullRubrik += result.KeyWords[i].Key_Word;
          }

          tmpEvent.Rubrik = fullRubrik;

          for (var j: number = 0; j < result.Texts.length; j++) {
            if (result.Texts[j].Text_Type_No === 1) {
              tmpEvent.Beschreibung = modifyNAVTexts(result.Texts[j].HTML_Content);
              break;
            }
          }
          
          for (var k: number = 0; k < result.Texts.length; k++) {
            if (result.Texts[k].Text_Type_No === 5) {
              tmpEvent.Zielgruppe = modifyNAVTexts(result.Texts[k].HTML_Content);
              break;
            }
          }
          tmpEvent.HinweisFront = result.Note_Front;
          var dozent: string = "";
          for (var l: number = 0; l < result.Leaders.length; l++) {
            if (result.Leaders[l].Bezeichnung_Name_Web === "") {
              dozent += result.Leaders[l].Name;
            } else {
              dozent += result.Leaders[l].Bezeichnung_Name_Web;
            }
            if (l !== result.Leaders.length - 1) {
              dozent += ", ";
            }
          }
          tmpEvent.Bezeichnung_Name_Web = dozent;
          for (var m: number = 0; m < result.Texts.length; m++) {
            if (result.Texts[m].Text_Type_No === 2) {
              tmpEvent.Programm = modifyNAVTexts(result.Texts[m].HTML_Content);
              break;
            }
          }


          for (var n: number = 0; n < result.Texts.length; n++) {
            if (result.Texts[n].Text_Type_No === 3) {
              tmpEvent.Seminarziel = modifyNAVTexts(result.Texts[n].HTML_Content);
              break;
            }
          }
          
          if (result.Services) {
            if (result.Services.length > 0) {
              for (var x: number = 0; x < result.Services.length; x++) {
                if (result.Services[x].Mitglieder_Leistung === false) {
                  tmpEvent.Amount_LCY_net = result.Services[x].Amount_LCY_net;
                }
                else if (result.Services[x].Mitglieder_Leistung === true) {
                  tmpEvent.Amount_LCY_net_Member = result.Services[x].Amount_LCY_net;
                }
              }
            }
          }
            const urlEndPart = "/sp/documents/event/" + key + "?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
            const azureCon = new AzureConnector();
            azureCon.get(urlEndPart)
              .then((result: any) => {
                console.log("get from NAV success");
                console.log(result);
                var content: string = "";
                if (result.length > 0) {
                  content = "<table> <tbody>";
                  for (var i: number = 0; i < result.length; i++) {
                    var name: string = result[i];
                    content = `<tr><td>
                              ${name}
                              </td><td>
                              <a href='/Dokumente/${name}' target='_blank'>
                              <img src='${process.env.PUBLIC_URL}/assets/images/DLTest.png' style='max-height: 20px'/>
                              </a>
                              </td>
                              </tr>`;
                  }

                  content += "</tbody> </table>";
                  tmpEvent.Dokumente = content;
                  setVeranstaltungBaseJson(tmpEvent);
                }
                else {
                  content = "<div>Es wurden keine Dokumente zu dieser Veranstaltung gefunden.</div>";
                  tmpEvent.Dokumente = content;
                  setVeranstaltungBaseJson(tmpEvent);
                }
              })
              .catch((error: any) => {
                console.log("get from NAV error");
                var content: string = "<div>Es wurden keine Dokumente zu dieser Veranstaltung gefunden.</div>";
                tmpEvent.Dokumente = content;
                setVeranstaltungBaseJson(tmpEvent);
              });
          }
        else
        {
          console.log("result key in  JSON is null",result);
          const urlEndPart = "/nav/events/" + key + "/?expand=leaders,texts,keywords,services&code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
          const azureCon = new AzureConnector();
          azureCon.get(urlEndPart)
            .then((result: any) => {
              console.log("get from NAV success",result);
              if (result !== undefined) {
                let tmpEvent: IVeranstaltungsdetailsProps = { Date: "",Location_Full:"",Rubrik : "",Beschreibung :""};
                tmpEvent=result;
                // tmpEvent.Description=result.Description;
                // tmpEvent.Description_2=result.Description2;
                // tmpEvent.Max_Participant=result.Max_Participant;
                // tmpEvent.Participant_total=result.Participant_total;
                var dateSplit: any[] = result.Start_date.split("T")[0].split("-");
                var fullDate: string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
                tmpEvent.Date = fullDate;
    
                if (result.Start_date !== result.End_date) {
                  dateSplit = result.End_date.split("T")[0].split("-");
                  fullDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
                  tmpEvent.Date = tmpEvent.Date + " - " + fullDate;
                }
    
                var fullLoca: string = result.Location_Name + ", " + result.Location_Address + ", " + result.Location_Post_Code + " " + result.Location_City;
                tmpEvent.Location_Full = fullLoca;
                var fullRubrik: string = "";
                for (var i: number = 0; i < result.KeyWords.length; i++) {
                  if (i !== 0) {
                    fullRubrik += ", ";
                  }
                  fullRubrik += result.KeyWords[i].Key_Word;
                }
    
                tmpEvent.Rubrik = fullRubrik;
    
                for (var j: number = 0; j < result.Texts.length; j++) {
                  if (result.Texts[j].Text_Type_No === 1) {
                    tmpEvent.Beschreibung = modifyNAVTexts(result.Texts[j].HTML_Content);
                    break;
                  }
                }
                
                for (var k: number = 0; k < result.Texts.length; k++) {
                  if (result.Texts[k].Text_Type_No === 5) {
                    tmpEvent.Zielgruppe = modifyNAVTexts(result.Texts[k].HTML_Content);
                    break;
                  }
                }
                tmpEvent.HinweisFront = result.Note_Front;
                var dozent: string = "";
                for (var l: number = 0; l < result.Leaders.length; l++) {
                  if (result.Leaders[l].Bezeichnung_Name_Web === "") {
                    dozent += result.Leaders[l].Name;
                  } else {
                    dozent += result.Leaders[l].Bezeichnung_Name_Web;
                  }
                  if (l !== result.Leaders.length - 1) {
                    dozent += ", ";
                  }
                }
                tmpEvent.Bezeichnung_Name_Web = dozent;
              for (var m: number = 0; m < result.Texts.length; m++) {
                  if (result.Texts[m].Text_Type_No === 2) {
                    tmpEvent.Programm = modifyNAVTexts(result.Texts[m].HTML_Content);
                    break;
                  }
                }
    
    
                for (var n: number = 0; n < result.Texts.length; n++) {
                  if (result.Texts[n].Text_Type_No === 3) {
                    tmpEvent.Seminarziel = modifyNAVTexts(result.Texts[n].HTML_Content);
                    break;
                  }
                }
                
                if (result.Services) {
                  if (result.Services.length > 0) {
                    for (var x: number = 0; x < result.Services.length; x++) {
                      if (result.Services[x].Mitglieder_Leistung === false) {
                        tmpEvent.Amount_LCY_net = result.Services[x].Amount_LCY_net;
                      }
                      else if (result.Services[x].Mitglieder_Leistung === true) {
                        tmpEvent.Amount_LCY_net_Member = result.Services[x].Amount_LCY_net;
                      }
                    }
                  }
                }
                const urlEndPart = "/sp/documents/event/" + key + "?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
                const azureCon = new AzureConnector();
                azureCon.get(urlEndPart)
                  .then((result: any) => {
                    console.log("get from NAV success");
                    console.log(result);
                    var content: string = "";
                    if (result.length > 0) {
                      content = "<table> <tbody>";
                      for (var i: number = 0; i < result.length; i++) {
                        var name: string = result[i];
                        content = `<tr><td>
                                  ${name}
                                  </td><td>
                                  <a href='/Dokumente/${name}' target='_blank'>
                                  <img src='${process.env.PUBLIC_URL}/assets/images/DLTest.png' style='max-height: 20px'/>
                                  </a>
                                  </td>
                                  </tr>`;
                      }
    
                      content += "</tbody> </table>";
                      tmpEvent.Dokumente = content;
                      setVeranstaltungBaseJson(tmpEvent);
                    }
                    else {
                      content = "<div>Es wurden keine Dokumente zu dieser Veranstaltung gefunden.</div>";
                      tmpEvent.Dokumente = content;
                      setVeranstaltungBaseJson(tmpEvent);
                    }
                  })
                  .catch((error: any) => {
                    console.log("get from NAV error");
                    var content: string = "<div>Es wurden keine Dokumente zu dieser Veranstaltung gefunden.</div>";
                    tmpEvent.Dokumente = content;
                    setVeranstaltungBaseJson(tmpEvent);
                  });
              }
            })
            .catch((error: any) => {
              console.log("get from NAV error");
            });
        }
  }}, [key, location.search, result]);

  React.useEffect(() => {
    if (veranstaltung != null && buchungsButton == null ) {
      var auslastung = parseInt(veranstaltung.Max_Participant) - parseInt(veranstaltung.Participant_total);
      if(veranstaltungNoExpand!==null)
      {
        auslastung = parseInt(veranstaltungNoExpand.Max_Participant) - parseInt(veranstaltungNoExpand.Participant_total);
      }
      
      
      if(currMetaData===null)
      {
        setcurrMetaData({});
        props.onChangeHeader({title:veranstaltung.Description,description:veranstaltung.Description_2,url:currShareUrl,image:"/assets/News/banner01.jpg"})
      }
      if (auslastung > 0) {
        setBuchungsButton(<DefaultButton className={"WvibButtonPrim_RegFat"} onClick={() => CreateNewBooking("anon")}>Buchen</DefaultButton>);
        setColoredDot(<img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/GreenDot.png`} alt="miroIcon"></img>);
      }
      else {
        setAusgebuchtText("Ausgebucht, bitte wenden Sie sich an die Veranstaltungsorganisation.");
        setBuchungsButton(<DefaultButton className={"WvibButtonPrim_RegFat_disabled"} disabled={true} onClick={() => CreateNewBooking("anon")}>Buchen</DefaultButton>);
        setColoredDot(<img className="microIcon" src={`${process.env.PUBLIC_URL}/assets/images/RedDot.png`} alt="miroIcon"></img>);
      }
    }
  }, [CreateNewBooking, buchungsButton, currMetaData, currShareUrl, props, veranstaltung, veranstaltungNoExpand]);

  try {
    var quicklinksWebpart: HTMLElement = document.querySelector('[data-viewport-id*="WebPart.QuickLinksWebPart"]') as HTMLElement;
    if (quicklinksWebpart != null) {
      quicklinksWebpart.style.display = "none";
    }

    var titleImg: HTMLElement = document.querySelectorAll('[data-viewport-id*="WebPart.ImageWebPart.internal"]')[1] as HTMLElement;
    if (titleImg != null) {
      titleImg.style.display = "none";
    }
  }
  catch { }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function CreateNewBooking(type: string) {
    setShowModal(true);

    // var currSearch:string = "?originUser=" + type + "&eventNo=" + veranstaltung.No;
    setAnlageNeuerBenutzerJsX(
      <div>
        <div>
          <DefaultButton className={"WvibButton"} id="closeButton" text="Schließen" onClick={closeModal} />
        </div>
        <div>
          <AnlageNeuerBenutzer description="" origin={type} veranstaltung={veranstaltung} closeModal={closeModal} />
        </div>
      </div>);
  }

  function closeModal() {
    setShowModal(false);
  }

  function parseHtmlEntities(str: any) {
    if (str !== undefined) {
      return str.replace(/&#([0-9]{1,4});/gi, function (match: any, numStr: string) {
        var num: number = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
      });
    }
    else {
      return "";
    }
  }

  function modifyNAVTexts(text: string) {
    var firstPos: number = 0;
    var lastPos: number = 0;
    var modifiedContent: string = text;
    while (modifiedContent.indexOf("WIDTH:") > -1) {
      firstPos = modifiedContent.indexOf("WIDTH:");
      lastPos = modifiedContent.substr(firstPos).indexOf(";") + firstPos + 1;
      modifiedContent = modifiedContent.substr(0, firstPos) + modifiedContent.substr(lastPos);
    }

    while (modifiedContent.indexOf("<FONT") > -1) {
      firstPos = modifiedContent.indexOf("<FONT");
      lastPos = modifiedContent.substr(firstPos).indexOf(">") + firstPos + 1;
      modifiedContent = modifiedContent.substr(0, firstPos) + modifiedContent.substr(lastPos);
    }

    while (modifiedContent.indexOf("</FONT") > -1) {
      firstPos = modifiedContent.indexOf("</FONT");
      lastPos = modifiedContent.substr(firstPos).indexOf(">") + firstPos + 1;
      modifiedContent = modifiedContent.substr(0, firstPos) + modifiedContent.substr(lastPos);
    }

    return modifiedContent;
  }

  function loadWordCloud() {
    return <WordCloud key="wordCloudKey" noLoad={true} extraTags={veranstaltung.KeyWords} />;
  }

  function Participate()
  {
    
  }

  function StornoParticipate()
  {

  }

  function generateParticipateButtons(){
    return <div>
      <DefaultButton className={"WvibButtonPrim_RegFat"} onClick={() => Participate()}>Teilnehmen</DefaultButton>
      <DefaultButton className={"WvibButtonPrim_RegFat"} onClick={() => StornoParticipate()}>Teilnahme stornieren</DefaultButton>
    </div>
  }

  function generateICalButton()
  {
    var startDate: string = result.Start_date.split("T")[0];
    var endDate: string = result.End_date.split("T")[0];

    return <AddToCalendarButton
      label="Kalendereintrag herunterladen"
      name={veranstaltung.Description}
      location={veranstaltung.Location_Address + ", " + veranstaltung.Location_Post_Code + " " + veranstaltung.Location_City}
      startDate={startDate}
      endDate={endDate}
      startTime={veranstaltung.Time_from}
      endTime={veranstaltung.Time_to}
      timeZone="Europe/Berlin"
      language="de" 
    ></AddToCalendarButton>
    // return <AddToCalendarButton
    //   name= {veranstaltung.Description}
    //   location= {veranstaltung.Location_Address + ", " + veranstaltung.Location_Post_Code + " " + veranstaltung.Location_City}
    //   startDate={veranstaltung.Start_date}
    //   endDate={veranstaltung.End_date}
    //   startTime={veranstaltung.Time_from}
    //   endTime={veranstaltung.Time_to}
    //   timeZone="Europe/Berlin"
    //   language="de" 
    // ></AddToCalendarButton>
  }

  if (veranstaltung == null) {
    return <div>
      <header className={"App-header"} style={{ fontFamily: 'Roboto' }}>
        <Header />
        <></>;
      </header>
      <Footer />
    </div>;
  }
  else if (veranstaltung !== null) {
    return (
    <div>
      <header className={"App-header"}>
        <Header />
        <div className={"WvibGenericPageLimiter"}>
          <div className={"container-fluid"}>
            <div className={"container-row"} style={{ fontFamily: 'Roboto' }}>
              <Modal
                isOpen={showModal}
                ariaHideApp={false}
                onRequestClose={() => closeModal()}
                contentLabel="Example Modal"
                className={"benutzerModal place-holder"}
                overlayClassName={"benutzerOverlay"}
              // style={modalStyle}
              >
                {anlageNeuerBenutzerJsX}
              </Modal>
              <div className='place-holder'>
                <DefaultButton className={"WvibButton"} onClick={() => history.goBack()}>Zurück</DefaultButton>
              </div>
              <table className={"WvibFullTable"} style={tableStyle}>
                <tbody>
                  <tr className='place-holder'>
                    <td className='place-holder' style={primaryCol}></td>
                    <td className='place-holder' style={secondaryCol}></td>
                  </tr>
                  <tr>
                    <td style={baseTd}>
                      {/* Titel */}
                      <h2 className='title-h1'>{veranstaltung.Description} ({veranstaltung.No})&nbsp;{coloredDot}</h2>
                    </td>
                    <td style={baseTd} rowSpan={2} className='place-holder'>
                      <div>
                        {buchungsButton}
                        {/* <DefaultButton className={AkademieStyles.WvibButtonPrim_RegFat} onClick={() => CreateNewBooking("anon")}>Buchen</DefaultButton> */}
                      </div>
                      <div style={baseTdRed}>
                        {ausgebuchtText}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={baseTd}>
                      {/* Untertitel */}
                      <h3>{veranstaltung.Description_2}</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={baseTd} >
                      {/* Beschreibung */}
                      <div dangerouslySetInnerHTML={{ __html: parseHtmlEntities(veranstaltung.Beschreibung) }} ></div>
                      {/* Programm */}
                      <div style={titleDiv_Black}>Das Programm</div>
                      <div dangerouslySetInnerHTML={{ __html: parseHtmlEntities(veranstaltung.Programm) }} ></div>
                      {/* Rubriken */}
                      <div style={{ marginTop: "5%" }}>
                        {loadWordCloud()}
                      </div>
                      {/* iCal */}
                      <div>
                        {generateICalButton()}
                      </div>
                      {/* Button Anmelden/Abmelden */}
                      <div>
                        {generateParticipateButtons()}
                      </div>
                    </td>
                    <td style={baseTd} >
                      {/* Ziel */}
                      <div className='subCols'>
                        <div className='titleDiv'>Ziel</div>
                        <div dangerouslySetInnerHTML={{ __html: parseHtmlEntities(veranstaltung.Seminarziel) }} ></div>
                      </div>
                      {/* HinweisFront */}
                      {hinweisText}
                      {/* Zielgruppe */}
                      <div className='subCols'>
                        <div className='titleDiv'>Zielgruppe</div>
                        <div dangerouslySetInnerHTML={{ __html: parseHtmlEntities(veranstaltung.Zielgruppe) }} ></div>
                      </div>
                      {/* Dokumente/Downloads  Anforderung Ticket 91603221
                      <div className='subCols'>
                        <div className='titleDiv'>Downloads</div>
                        <div dangerouslySetInnerHTML={{ __html: veranstaltung.Dokumente }} ></div>
                      </div>
                      */}
                      {/* Ansprechpartner */}
                      <div className='subCols'>
                        <div className='titleDiv'>Dozent(en)</div>
                        <div>
                          {veranstaltung.Bezeichnung_Name_Web}
                        </div>
                      </div>
                      <div className='subCols'>
                        <div className='titleDiv'>Veranstaltungsorganisation</div>
                        <div >
                          {veranstaltung.Assistenz_Name}
                        </div>
                        <div>
                          <a href={"mailto:" + veranstaltung.Assistenz_Name_Email} style={{ color:'#c50023' }}>
                            {veranstaltung.Assistenz_Name_Email}
                          </a>
                        </div>
                      </div>
                      <div className='subCols'>
                        <div className='titleDiv'>Ansprechpartner/in</div>
                        <div>
                          {veranstaltung.Contact_Person_Name}
                        </div>
                        <div>
                          <a href={"mailto:" + veranstaltung.Contact_Person_Email} style={{ color:'#c50023' }}>
                            {veranstaltung.Contact_Person_Email}
                          </a>
                        </div>
                      </div>
                      {/* Termin */}
                      <div className='subCols'>
                        <div className='titleDiv'>Termin</div>
                        <div>
                          {veranstaltung.Date}
                        </div>
                        <div>
                          {veranstaltung.Time_from}-{veranstaltung.Time_to}
                        </div>
                      </div>
                      {/* Ort */}
                      <div className='subCols'>
                        <div className='titleDiv'>Ort</div>
                        <div>
                          {veranstaltung.Location_Name}
                        </div>
                        <div>
                          {veranstaltung.Location_Address}, {veranstaltung.Location_Post_Code} {veranstaltung.Location_City}
                        </div>
                      </div>
                      {/* Teilnehmer */}
                      <div className='subCols'>
                        <div className='titleDiv'>Max. Teilnehmer</div>
                        <div>
                          {/* {veranstaltung.Participant_total}/{veranstaltung.Max_Participant} */}
                          {veranstaltung.Max_Participant}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    {/* Anmelden */}
                    <td style={baseTd}>
                      {/* <div>
                        <DefaultButton className={"WvibButtonPrim"} id="loginButton" text={loginText} onClick={() => LoginBooking()} disabled={loginDisabled}/>
                        <DefaultButton className={"WvibButton"} id="newUserButton" text="Neuen Benutzer erstellen und buchen" onClick={() => CreateNewBooking("new")} />
                        <DefaultButton className={"WvibButton"} id="newUserAnonButton" text="Gastkonto erstellen und buchen" onClick={() => CreateNewBooking("anon")} />
                      </div> */}
                    </td>
                    <td style={baseTd} className='place-holder'>
                      <div>
                        {buchungsButton}
                      </div>
                      <div style={baseTdRed}>
                        {ausgebuchtText}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </header>
      <Footer />
    </div>);
  }else{
      
  return (<div className="row  loading-box">
    <ReactLoading color={"#293940"} width={175} type={"spin"} />
  </div>)
  }

}
