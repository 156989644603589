import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DefaultButton, Label } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import NeuerBenutzerComponent, { iBenutzerdaten } from './NeuerBenutzerComponent_Anon';
import NeuerBenutzerComponentLite from './NeuerBenutzerComponentLite_Anon';
import AbweichendeRechnungComponent, { iAbweichendeRechnungdaten } from './AbweichendeRechnungComponent_Anon';

import AzureConnector from '../Module/AzureConnector_Anon';
import RechnungComponent, { iRechnungdaten } from './RechnungComponent_Anon';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/AkademieStyles.css');
export interface IAnlageNeuerBenutzerProps {
  description: string;
  origin: string;
  veranstaltung: IVeranstaltungsdetailsProps;
  closeModal: any;
}

export interface IVeranstaltungsdetailsProps {
  No?: string;
  Description?: string;
  Description_2?: string;
  KeyWords?: any;
  Rubrik?: string;
  Texts?: any;
  Beschreibung?: string;
  Start_date?: string;
  End_date?: string;
  Time_from?: string;
  Time_to?: string;
  Date?: string;
  Max_Participant?: string;
  Min_Participant?: string;
  Participant_total?: string;
  Location_Name?: string;
  Location_City?: string;
  Location_Post_Code?: string;
  Location_Address?: string;
  Location_Full?: string;
  Amount_LCY_net?: string;
  Amount_LCY_net_Member?: string;
  Dokumente?: string;
  Zielgruppe?: string;
  HinweisFront?: string;
  Note_Front?: string;
  Leaders?: [{ No: string, Name: string, Bezeichnung_Name_Web: string }];
  Bezeichnung_Name_Web?: string;
  Programm?: string;
  Seminarziel?: string;
  Services?: [{ Amount_LCY_net: string, Mitglieder_Leistung: boolean }];
  Assistenz_Name?: string;
  Assistenz_Name_Email?: string;
  Contact_Person_Name?: string;
  Contact_Person_Email?: string;
}


export default function AnlageNeuerBenutzer(props: IAnlageNeuerBenutzerProps) {
  const location = useLocation();
  const [userOrigin, setUserOrigin] = React.useState(null);
  const [eventNo, setEventNo] = React.useState(null);
  const [headline, setHeadline] = React.useState(userOrigin);
  const [fullUserCount, setFullUserCount] = React.useState<number>(1);
  const [userCount, setUserCount] = React.useState<number>(1);
  const history = useHistory();
  const [bookButtonText, setBookButtonText] = React.useState(userOrigin);
  const [abweichend, setAbweichend] = React.useState(Boolean);
  const [userArray, setUserArray] = React.useState<iBenutzerdaten[]>([{ userKey: fullUserCount, Salutation: '', Company_Name: '', E_Mail: '', City: '', Post_Code: '', Address: '', Address_2: '', Surname: '', Phone_No: '', First_Name: '', Event_No: '', No: '', Occupation: '', Leader: false, Remark: '', Portalbenutzer: false }]);
  const [altBillTo, setAltBillTo] = React.useState<iAbweichendeRechnungdaten>({ Bill_to_Address: '', Bill_to_Address_2: '', Bill_to_Post_Code: '', Bill_to_City: '', Bill_to_Company_Name: '', Bill_to_E_Mail: '', Bill_to_Internal_No: '', Bill_to_as_E_Mail: false });
  const [billTo, setBillTo] = React.useState<iRechnungdaten>({ Company_Name: '', City: '', Post_Code: '', Address: '', Address_2: '', Bill_to_E_Mail: '', Bill_to_Internal_No: '', Bill_to_as_E_Mail: false });
  const [usersRegistered, setUsersRegistered] = React.useState(0);
  const azureCon = new AzureConnector();
  const [datenschutz, setDatenschutz] = React.useState(false);
  const [mailOk, setMailOk] = React.useState(false);
  const [summaryShown, setSummaryShown] = React.useState(false);
  const [agbRead, setAgbRead] = React.useState(false);
  const [registerOtherUsers, setRegisterOtherUsers] = React.useState(false);
  const [registerSelf, setRegisterSelf] = React.useState(false);
  const [istAbweichendAdresse, setIstAbweichendAdresse] = React.useState(false);


  let modularStyle: React.CSSProperties = {
    backgroundColor: '#F1F1F1'
  }

  React.useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.has('originUser')) {
      setUserOrigin(search.get('originUser'));
    }
    else if (props.origin !== "") {
      setUserOrigin(props.origin);
    }
    if (search.has('eventNo')) {
      setEventNo(search.get('eventNo'));
    }
    else if (search.has('key')) {
      setEventNo(search.get('key'));
    }

  }, [location, props.origin]);

  React.useEffect(() => {
    if (usersRegistered > 0) {
      alert("Vielen Dank! Ihre Buchungsanfrage wurde erfolgreich übermittelt. Unser Team wird sich innerhalb von einem Werktag mit einer Bestätigung bei Ihnen melden. ");
      setUsersRegistered(0);
      var closeButton: HTMLElement = document.getElementById("closeButton");
      closeButton.click();
    }
  }, [usersRegistered]);

  React.useEffect(() => {
    userOriginBaseParameters();
    // if(userCount===0)
    // {
    // setUserCount(1);
    // setFullUserCount(1);
    // }
  }, [userOrigin, userOriginBaseParameters]);

  try {
    var quicklinksWebpart: HTMLElement = document.querySelector('[data-viewport-id*="WebPart.QuickLinksWebPart"]') as HTMLElement;
    quicklinksWebpart.style.display = "none";

    var titleImg: HTMLElement = document.querySelectorAll('[data-viewport-id*="WebPart.ImageWebPart.internal"]')[1] as HTMLElement;
    titleImg.style.display = "none";
  }
  catch { }

  if (!userOrigin) {
    return <></>;
  }
  const meAndOrOthersJsx = fillMeAndOrOthersJsx();
  const neueBenutzerJsx = fillNeueBenutzer(userCount);
  const veranstaltungJsx = fillVeranstaltung(props.veranstaltung)
  const rechnungJsx = fillRechnungAdress();
  const abweichendeRechnungJsx = fillAbweichendeRechnung(abweichend);
  const checkAgainJsx = fillCheckAgainJsx();
  const datenschutzJsx = loadDatenschutz();
  const summaryJsx = fillSummary(props.veranstaltung);
  const addUserButton = userOrigin === 'anon'
    ? <DefaultButton
      className={"WvibButtonPrim"}
      key="addBtn"
      text="weiteren Teilnehmer hinzufügen"
      onClick={() => {
        const currUserKey = fullUserCount + 1;
        setUserCount(userCount + 1);
        setFullUserCount(currUserKey);
        setUserArray([...userArray, { userKey: currUserKey, Salutation: '', Company_Name: '', E_Mail: '', City: '', Post_Code: '', Address: '', Address_2: '', Surname: '', Phone_No: '', First_Name: '', Event_No: '', No: '', Occupation: '', Remark: '', Leader: false, Portalbenutzer: false }]);
      }}
      allowDisabledFocus
    />
    : null;

  return (
    <div className={"container-fluid"} style={modularStyle}>
      <div className={"container-row"} style={{ fontFamily: 'Roboto' }}>
        <div hidden={summaryShown}>
          <div className="eventSummaryDiv">
            <h2>Veranstaltung buchen</h2>
            <div>
              Willkommen! Wir freuen uns über Ihr Interesse an dieser Veranstaltung. Hier können Sie Angaben zu Ihrer Buchung machen.
            </div>
            <div className="eventSummaryInnerDiv">
              <h2>Veranstaltungsdaten</h2>
              {veranstaltungJsx}
            </div>
          </div>
          <div className="contactDiv">
            <h2>Kontaktdaten</h2>
            <div>
              Wir benötigen für die Buchung die nachfolgend aufgeführten Daten.
            </div>
          </div>
          <div className="neueBenutzerDiv">
            {neueBenutzerJsx}
          </div>
          <div className={"anmeldeCBs"}>
            {meAndOrOthersJsx}
          </div>
          <div className="clearDiv"></div>
          <div className={"addUserButtonDiv"} hidden={!registerOtherUsers}>
            {addUserButton}
          </div>
          <div className="contactDiv">
            <h2>Rechnungsadresse</h2>
          </div>
          <div className={"rechnungsDatenDiv"}>
            {rechnungJsx}
          </div>
          <div className="contactDiv">
            <p className='abweichen-paragraph'>
              <input type="checkbox" id={"Arechung"} checked={istAbweichendAdresse} className="abweichenCheckbox" onChange={
                () => {
                  setIstAbweichendAdresse(!istAbweichendAdresse);
                }} />
              <h2>Abweichende Rechnungsadresse</h2>
            </p>
            <p className='abweich-txt'>
              Nur wenn Sie die Rechnung an eine andere, als die oben angegebene Rechnungsadresse wünschen, geben
              Sie diese bitte hier ein. Felder zur „Abweichenden Rechnungsadresse“
            </p>
          </div>
          {istAbweichendAdresse ?
            <div className={"rechnungsDatenDiv"}>
              {abweichendeRechnungJsx}
            </div> : null}
          <div className={"datenschutzDiv"}>
            {datenschutzJsx}
          </div>
          <div className={"checkAgainDiv"}>
            {checkAgainJsx}
          </div>
        </div>
        <div className={"summaryDiv"} hidden={!summaryShown}>
          <h2>Zusammenfassung</h2>
          {summaryJsx}
        </div>
      </div>
    </div>

  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function userOriginBaseParameters() {
    setAbweichend(false);
    if (userOrigin === "anon") {
      setBookButtonText("Benutzer ohne Registrierung anmelden");
      setHeadline("Veranstaltung buchen");
    }
    else {
      setBookButtonText("Benutzer registrieren und buchen");
      setHeadline("Neuen Benutzer anlegen");
    }
  }

  function fillNeueBenutzer(anzahl: number) {
    var contentPart: any[] = [];

    for (let i = 0; i < userArray.length; i++) {
      if (anzahl > 1) {
        if (registerSelf) {
          contentPart.push(<h2 style={{ "paddingLeft": "3%" }} key={"headerKey" + i}>{i + 1}. Teilnehmer</h2>);
        }
        else {
          if (i > 0)
            contentPart.push(<h2 style={{ "paddingLeft": "3%" }} key={"headerKey" + i}>{i}. Teilnehmer</h2>);
        }
      }
      if (i === 0) {
        contentPart.push(<NeuerBenutzerComponent key={"key" + userArray[i].userKey} userData={userArray[i]} userNummer={"" + userArray[i].userKey} onDelete={num => {
          const userKey = parseInt(num);
          const tmpUserArray = userArray.filter((obj) => obj.userKey !== userKey);
          setUserArray(tmpUserArray);
          setUserCount(userCount - 1);
          const currUserKey = fullUserCount - 1;
          setFullUserCount(currUserKey);

        }} userOrigin={props.origin} participant={registerSelf} />);
        contentPart.push(<div className={"anmeldeCBs"}>
          {meAndOrOthersJsx}
        </div>)
      }
      else {
        contentPart.push(<NeuerBenutzerComponentLite key={"key" + userArray[i].userKey} userData={userArray[i]} userNummer={"" + userArray[i].userKey} onDelete={num => {
          const userKey = parseInt(num);
          const tmpUserArray = userArray.filter((obj) => obj.userKey !== userKey);
          setUserArray(tmpUserArray);
          setUserCount(userCount - 1);
          const currUserKey = fullUserCount - 1;
          setFullUserCount(currUserKey);
        }} userOrigin={props.origin} />);
      }
    }

    return contentPart;
  }

  function fillVeranstaltung(veranstaltung: IVeranstaltungsdetailsProps) {
    var contentPart: any[] = [];

    //Titel

    var title: string = veranstaltung.Description.trim();
    // if(veranstaltung.Description_2 != undefined)
    // {
    //   title += " " + veranstaltung.Description_2.trim();
    // }

    title += " (" + veranstaltung.No.trim() + ")"

    contentPart.push(
      <TextField className="disabledTexts" id={"veranstaltungTitel"} value={title} disabled={true} label="Veranstaltungstitel" />
    )

    //Termin/Mehrtägig
    var date: string = "";

    var dateSplit: any[] = veranstaltung.Start_date.split("T")[0].split("-");
    var fullDate: string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
    date = fullDate;

    // BenchM
    // if(veranstaltung.Start_date != veranstaltung.End_date)
    // {
    //   dateSplit = veranstaltung.End_date.split("T")[0].split("-");
    //   fullDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
    //   date = date + " - " + fullDate;
    // }

    // date = date;
    //date = date + ", " + veranstaltung.Time_from + " - " + veranstaltung.Time_to;


    contentPart.push(
      <TextField className="disabledTexts" id={"veranstaltungZeit"} value={date} disabled={true} label="Veranstaltungsbeginn" />
    )

    //Ort
    contentPart.push(
      <TextField className="disabledTexts" id={"veranstaltungOrt"} value={veranstaltung.Location_Full} disabled={true} label="Veranstaltungsort" />
    )

    //Preis
    if (props.origin === "anon") {
      //   contentPart.push(
      //     <TextField className="disabledTexts" id={"veranstaltungPreis"} value={veranstaltung.Amount_LCY_net} disabled={true} label="Veranstaltungspreis je TN"/>
      //   )
      // }
      // else{
      //var currValue = veranstaltung.Amount_LCY_net+ ",-€";
       var currValue = veranstaltung.Amount_LCY_net_Member + ",-€";
      
      console.log("currValue: ", currValue);
      contentPart.push(
        <TextField className="disabledTexts" id={"veranstaltungPreis"} value={veranstaltung.Amount_LCY_net_Member} disabled={true} label="Veranstaltungspreis je TN" />
      )
    }

    return contentPart;

  }

  function checkForSummary() {
    if(!registerSelf && !registerOtherUsers)
    {
      alert("Sie müssen angeben, ob Sie sich selbst, und/oder andere Teilnehmer anmelden wollen.");
      return;
    }
    if (!datenschutz) {
      alert("Bitte bestätigen Sie, dass Sie die Datenschutzerklärung gelesen haben.");
      return;
    }
    // else if (!mailOk) {
    //   alert("Bitte stimmen sie den AGB zu");
    //   return;
    // }

    var thereWasAnError: boolean = false;
    var userErrors: any[] = [];

    for (var i: number = 0; i < userArray.length; i++) {
      var checkUser: iBenutzerdaten = userArray[i];
      var thereWasAnUserError: boolean = false;
      var userErrorArray: any[] = []

      if (checkUser.Salutation === '') {
        thereWasAnUserError = true;
        userErrorArray.push("Anrede");
      }
      if (checkUser.First_Name === '') {
        thereWasAnUserError = true;
        userErrorArray.push("Vorname");
      }
      if (checkUser.Surname === '') {
        thereWasAnUserError = true;
        userErrorArray.push("Nachname");
      }
      if (checkUser.Occupation === '') {
        thereWasAnUserError = true;
        userErrorArray.push("Tätigkeit");
      }
      if (i === 0) {
        if (checkUser.E_Mail === '') {
          thereWasAnUserError = true;
          userErrorArray.push("Email");
        }
      
      }
      if (thereWasAnUserError) {
        thereWasAnError = true;
        userErrors.push([i, userErrorArray]);
      }
    }

    var thereWasAnErrorRechnung: boolean = false;
    var rechnungError: any[] = [];


    if (istAbweichendAdresse) {
      if (altBillTo.Bill_to_Company_Name === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Unternehmen");
      }
      if (altBillTo.Bill_to_Post_Code === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Postleitzahl");
      }
      if (altBillTo.Bill_to_City === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Ort");
      }
      if (altBillTo.Bill_to_Address === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Straße");
      }


      if (altBillTo.Bill_to_as_E_Mail && altBillTo.Bill_to_E_Mail === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Email");
      }
    } else {
      if (billTo.Company_Name === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Unternehmen");
      }
      if (billTo.Post_Code === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Postleitzahl");
      }
      if (billTo.City === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Ort");
      }
      if (billTo.Address === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Straße");
      }


      if (billTo.Bill_to_as_E_Mail && billTo.Bill_to_E_Mail === '') {
        thereWasAnErrorRechnung = true;
        rechnungError.push("Email");
      }

    }

    var userText: any[] = [];
    if (thereWasAnError) {
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      for (var i: number = 0; i < userErrors.length; i++) {
        var text: string = "Benutzer " + (parseInt(userErrors[i][0]) + 1) + ": ";
        var userContent: any[] = userErrors[i][1];
        for (var j: number = 0; j < userContent.length; j++) {
          text += userContent[j];
          if (j < userContent.length - 1) {
            text += ", ";
          }
          else if (j === userContent.length - 1 && i < userErrors.length - 1) {
            text += "; \n";
          }
          else {
            text += ". \n";
          }
        }
        userText.push(text);
      }
    }

    var rechnungText: string = "";
    if (thereWasAnErrorRechnung) {
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var rechnungText: string = "Rechnungsdaten: ";
      if (istAbweichendAdresse) {
        rechnungText = "Abweichende Rechnungsadresse: ";
      }
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      for (var j = 0; j < rechnungError.length; j++) {
        rechnungText += rechnungError[j];
        if (j < rechnungError.length - 1) {
          rechnungText += ", ";
        }
        else {
          rechnungText += ". \n";
        }
      }
    }

    if (thereWasAnError || thereWasAnErrorRechnung) {
      var finalErrorMessage: string = "Es ist ein Fehler aufgetreten. Bitte kontrollieren Sie folgende Felder: \n";
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      for (var i = 0; i < userText.length; i++) {
        finalErrorMessage += userText[i];
      }
      finalErrorMessage += rechnungText;
      finalErrorMessage += "\nBitte ergänzen Sie die Angaben um fortzufahren.";
      alert(finalErrorMessage);
      return;
    }

    setSummaryShown(true);
  }

  function fillMeAndOrOthersJsx() {
    return (
      <div>
         <div>
         <input type="checkbox" id={"CBMe"} checked={registerSelf} className="floatingCheckbox" onChange={ 
          () => {
            var currentUsers:iBenutzerdaten[] = userArray;
            setUserArray(currentUsers);
            setRegisterSelf(!registerSelf);
          }}/>
          <div className="floatingElement">
            Ich nehme selbst teil und buche für mich.
          </div>
        </div> 
        <div className="clearDiv">
          {/* <Checkbox id={"CBMe"} checked={registerSelf} className="datenschutzCB" label={"Ich nehme selbst teil und buche für mich ..."}  onChange={ 
          (e, checked) => {
            var currentUsers = userArray;
            setUserArray(currentUsers);
            setRegisterSelf(checked);
          }}/> */}
          <input type="checkbox" id={"CBOthers"} checked={registerOtherUsers} className="floatingCheckbox" /*style={{marginLeft: "5%"}}*/ onChange={
            () => {
              if (!registerOtherUsers) {
                const currUserKey = fullUserCount + 1;
                setUserCount(userCount + 1);
                setFullUserCount(currUserKey);
                setUserArray([...userArray, { userKey: currUserKey, Salutation: '', Company_Name: '', E_Mail: '', City: '', Post_Code: '', Address: '', Address_2: '', Surname: '', Phone_No: '', First_Name: '', Event_No: '', No: '', Occupation: '', Remark: '', Leader: false, Portalbenutzer: false }]);
              }
              else {
                const tmpUserArray = [];
                tmpUserArray.push(userArray[0])
                setUserArray(tmpUserArray);
                setUserCount(1);
                setFullUserCount(1);
              }
              setRegisterOtherUsers(!registerOtherUsers)
            }} />
          <div className="floatingElement">
            Ich buche für eine andere Person/ andere Personen.
          </div>
        </div>
        {/* <Checkbox id={"CBOthers"} checked={registerOtherUsers} className="datenschutzCB" label={"... und/oder für andere"}  onChange={ 
              (e, checked) => {
              if(checked)
              {
                const currUserKey = fullUserCount+1;
                setUserCount(userCount+1);
                setFullUserCount(currUserKey);
                setUserArray([...userArray, {userKey: currUserKey, Salutation: '', Company_Name: '', E_Mail: '', City: '', Post_Code: '', Address:'', Address_2:'', Surname:'', Phone_No:'', First_Name:'', Event_No:'', No:'', Occupation:'', userIsLeitung_DUMMY:false, Portalbenutzer:false}]);
              }
              else
              {
                const tmpUserArray = [];
                tmpUserArray.push(userArray[0])
                setUserArray(tmpUserArray);
                setUserCount(1);
                setFullUserCount(1);
              }
              setRegisterOtherUsers(checked);
            }}/> */}
      </div>

    )
  }

  function fillAbweichendeRechnung(checked: any) {
    // if(checked)
    // {
    return <AbweichendeRechnungComponent key={"abweichendeRechnungKey"} description="" rechnungData={altBillTo} />;
    // }
    // else
    // {
    //   return <></>;
    // }
  }
  function fillRechnungAdress() {
    return <RechnungComponent key={"RechnungKey"} description="" rechnungData={billTo} />;
  }

  function fillCheckAgainJsx() {
    var content: any[] = [];

    content.push(<div>Prüfen Sie bitte alle Eingaben noch einmal. Wenn alles korrekt ist, klicken Sie bitte auf „Zur Zusammenfassung“</div>);
    content.push(<div>
      <DefaultButton className="WvibButton_Reg" id="datenschutzButton" text="Buchung abbrechen" onClick={props.closeModal} />
      <DefaultButton className="WvibButtonPrim_Reg" id="datenschutzButton" text="Zur Zusammenfassung" onClick={(e) => { e.preventDefault(); checkForSummary() }} />
    </div>);

    return content;
  }

  function loadDatenschutz() {
    var datenschutzArray: any = [];

    datenschutzArray.push(<h2>Datenschutzerklärung</h2>)
    datenschutzArray.push(<div>Bitte bestätigen Sie, dass Sie die Datenschutzerklärung gelesen haben.</div>)

    var link: JSX.Element = <div className="datenSchutzLink"><span className="datenSchutzLinkSpan">
      {/* <DefaultButton className="WvibButtonPrim_Reg" id="datenschutzButton" text="Datenschutzerklärung" onClick={(e) => {e.preventDefault(); window.location.href='https://red.wvib.de/#/Pages/Datenschutz.aspx';}}/> */}
      <DefaultButton className="WvibButtonPrim_Reg" id="datenschutzButton" text="Datenschutzerklärung" onClick={(e) => { e.preventDefault(); window.location.href = '/Pages/Datenschutz.aspx'; }} />
    </span></div>;

    datenschutzArray.push(
      <div className="datenSchutzCBOuter">
        <input type="checkbox" id={"CBDatenschutz"} className="datenschutzCB" checked={datenschutz} onChange={
          () => {
            setDatenschutz(!datenschutz);
          }} />{link}
        {/* <Checkbox id={"CBDatenschutz"} className="datenschutzCB" checked={datenschutz} label={""} onChange={
          (e, checked) => {
            setDatenschutz(checked);
          }}/>{link} */}
      </div>
    );
    
    // datenschutzArray.push(
    //   <div className="datenSchutzCBOuter">
    //     <input type="checkbox" id={"CBMailOK"} checked={mailOk} className="datenschutzCB" onChange={
    //       () => {
    //         setMailOk(!mailOk);
    //       }} />Ich habe die <a href={`${process.env.PUBLIC_URL}/Pages/AGB-der.aspx`} target="_blank" rel="noreferrer">AGB</a> der wvib Akademie zur Kenntnis genommen und bin mit deren Geltung einverstanden.
        
    //   </div>
    // );
    

    return datenschutzArray;
  }

  function fillSummary(veranstaltung: any) {
    var title: string = veranstaltung.Description.trim();
    title += " (" + veranstaltung.No.trim() + ")"

    var date: string = "";
    var dateSplit: any[] = veranstaltung.Start_date.split("T")[0].split("-");
    var fullDate: string = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
    date = fullDate;

    // benchm es wird nur Veranstaltungsbegin angezeigt
    // if(veranstaltung.Start_date !== veranstaltung.End_date)
    // {
    //   dateSplit = veranstaltung.End_date.split("T")[0].split("-");
    //   fullDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];
    //   date = date + " - " + fullDate;
    // }
    // date = date + ", " + veranstaltung.Time_from + " - " + veranstaltung.Time_to;

    // var agbLink = <div className="datenSchutzLink"><span className="datenSchutzLinkSpan">Ich habe die <a style={{color:"rgb(197, 0, 35)",textDecoration:"underline",cursor:"pointer"}} onClick={(e)=>{window.open("https://red.wvib.de/#/Pages/AGB-der.aspx","_blank")}}>AGB</a> gelesen und bin damit einverstanden</span></div>;
    var agbLink: JSX.Element = <div className="datenSchutzLink"><span className="datenSchutzLinkSpan">Ich habe die<button style={{ color: "rgb(197, 0, 35)", textDecoration: "underline", cursor: "pointer" }} onClick={() => { window.open("/Pages/AGB-der.aspx", "_blank") }}>AGB</button> gelesen und bin damit einverstanden</span></div>;
    var fullPrice: number = 0;
    var currentFulluserCount: number = fullUserCount
     if (!registerSelf) {
       currentFulluserCount = fullUserCount - 1;
     }
    //  Benchm es ist ANonymseite Mitglieder können oner SPO buchen
    // for(var i = 0; i < currentFulluserCount; i++)
    // {
    //   if(i < 2)
    //   {
    //     fullPrice += veranstaltung.Amount_LCY_net_Member
    //   }
    //   else
    //   {
    //     fullPrice += (veranstaltung.Amount_LCY_net_Member*(0.75))
    //   }
    // }

    for (var i: number = 0; i < currentFulluserCount; i++) {
      if (i < 2) {
        fullPrice += veranstaltung.Amount_LCY_net
      }
      else {
        fullPrice += (veranstaltung.Amount_LCY_net * (0.75))
      }
    }

    var contentPart: JSX.Element = <div>
      <div>
        <TextField id={"veranstaltungTitel"} className="disabledTextSummary" value={title} disabled={true} label="Veranstaltungstitel" />
      </div>
      <div>
        <TextField id={"veranstaltungZeit"} className="disabledTextSummary" value={date} disabled={true} label="Veranstaltungsbeginn" />
      </div>
      <div>
        <TextField id={"veranstaltungOrt"} className="disabledTextSummary" value={veranstaltung.Location_Full} disabled={true} label="Veranstaltungsort" />
      </div>
      <div className="flexDivLeft">
        <TextField id={"veranstaltungTeilnehmer"} className="disabledTextSummary" value={currentFulluserCount.toString()} disabled={true} label="Anzahl Teilnehmer" />
      </div>
      <div className="flexDivRight">
        <TextField id={"veranstaltungPreis"} className="disabledPriceSummary" value={veranstaltung.Amount_LCY_net} disabled={true} label="Einzelpreis" />
      </div>
      <div className="clearDiv"></div>
      <div className="flexDivLeft txtHinweis">
        <Label className="txt-Hinweis-label">
        Unser Buchungssystem zeigt den Preis für Nicht-Mitglieder an. Ihre Bestellung wird jedoch im Nachgang geprüft. Wenn Sie bei einem wvib-Mitglied beschäftigt sind, berechnen wir selbstverständlich den reduzierten Mitgliederpreis.  
          </Label>
        {/* <br/> */}
        <Label className="txt-Hinweis-label"><span style={{fontWeight:'bold'}}>Bitte beachten</span>: Der Rabatt ab dem dritten Teilnehmer gilt nicht für Lehrgänge und Veranstaltungen, die wir in Kooperation mit anderen Bildungsanbietern durchführen.</Label>
      </div>
      <div className="clearDiv"></div>
      <div className="flexDivRight">
        <TextField id={"veranstaltungPreisGesamt"} className="disabledPriceSummary" value={fullPrice.toString()} disabled={true} label="Gesamtpreis (Inkl. Rabatt von 25% für den dritten und jeden weiteren TN)" />
      </div>
       <div className="clearDiv">
        <input type="checkbox" id={"CBMailOK"} checked={agbRead} className="datenschutzCB" onChange={
          () => {
            setAgbRead(!agbRead);
          }} />
        {agbLink}
      </div>
      <div>
        <DefaultButton className="WvibButton_Reg" id="datenschutzButton" text="zurück" onClick={(e) => { e.preventDefault(); setSummaryShown(false) }} />
        <DefaultButton className="WvibButton_Reg" id="datenschutzButton" text="abbrechen" onClick={props.closeModal} />
        <DefaultButton className="WvibButtonPrim_Reg" id="datenschutzButton" text="verbindlich buchen" onClick={(e) => { e.preventDefault(); bookEvent() }} />
      </div>

    </div>

    return contentPart;
  }

  function bookEvent() {
     if (!agbRead) {
       alert("Bitte bestätigen Sie die Kenntnisnahme der AGB.")
       return;
     }
    var subscribe: any = { "Event_No": eventNo, "Portalbenutzer": false };

    if (userArray[0].Portalbenutzer) {
      subscribe = { "Event_No": eventNo, "Portalbenutzer": true };
      console.log("registrieren & anmelden");
    }
    else {
      console.log("anonym anmelden");
    }

    const urlEndPart = "/nav/registrations?code=zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==&clientId=publicweb";
    for (var i: number = 0; i < userArray.length; i++) {
       if (i === 0 && !registerSelf) {
         continue;
       }
      var currentUser: iBenutzerdaten = userArray[i];
      // currentUser.Post_Code = userArray[0].Post_Code;
      // currentUser.City = userArray[0].City;
      // currentUser.Address = userArray[0].Address;
      // currentUser.Address_2 = userArray[0].Address_2;
      // currentUser.Company_Name = userArray[0].Company_Name;
      // if (currentUser.E_Mail === "") {
      //   currentUser.E_Mail = userArray[0].E_Mail.trim();
      // }
      console.log("Remark", userArray[i].Remark);
      currentUser.Remark = userArray[0].E_Mail.trim();

      // currentUser.Company_Name = userArray[0].Company_Name;
       currentUser.Portalbenutzer = userArray[0].Portalbenutzer;
       subscribe = { "Event_No": eventNo, "Portalbenutzer": currentUser.Portalbenutzer };

      delete currentUser.Portalbenutzer;
      var registerPack: any = { ...currentUser, ...subscribe };
      if(istAbweichendAdresse){
        if (altBillTo.Bill_to_Post_Code !== '' && altBillTo.Bill_to_City !== '' && altBillTo.Bill_to_Address !== '' && altBillTo.Bill_to_Company_Name !== '') {
          registerPack = { ...currentUser, ...altBillTo, ...subscribe };
        }
      }else{
        if (billTo.Post_Code !== '' && billTo.City !== '' && billTo.Address !== '' && billTo.Company_Name!=='') {
          registerPack = { ...currentUser, ...billTo, ...subscribe };
        }
      }
    
      delete registerPack.userKey;
      // console.log(JSON.stringify(registerPack));
      azureCon.post(urlEndPart, JSON.stringify(registerPack))
        .then((result: any) => {
          console.log("result: " + result);
          setUsersRegistered(usersRegistered + 1);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }
}
