import React from 'react';
import ScrollTopArrow from './components/ScrollTopArrow';
// import CookieBot from './components/CookieBot';
// import {Helmet} from "react-helmet";

import {
  // BrowserRouter as Router,
  Switch,
  BrowserRouter,
  Route,
} from "react-router-dom";
import Home from './components/Home';
import Information from './components/information';
import Posts from './components/posts';
import Search from './components/search';
import Pages from './components/Pages';
import { PnPClientStorage } from "@pnp/common";
import AzureConnector from'./Module/AzureConnector';
import Veranstaltungsübersicht from './components/Veranstaltungsübersicht_Anon';
import Veranstaltungsdetails from './components/Veranstaltungsdetails_Anon';
// import Veranstaltungsdetails_Cluster from './components/Veranstaltungsdetails_Cluster_Anon';
import AnlageNeuerBenutzer from './components/AnlageNeuerBenutzer_Anon';
import StartseiteAkademie from './components/StartseiteAkademie_Anon';
// import buildSitemap from 'react-build-sitemap';
import {Helmet} from 'react-helmet-async';
import TestSeite from './components/TestSeite';
import EventsCardbc from './components/EventsCardBCTEST';
import VeranstaltungsdetailsCluster from './components/Veranstaltungsdetails_Cluster_Anon';
// import { Metadata } from './back_module_files/pdf';
const azurecon = new AzureConnector();
const storage = new PnPClientStorage();
const storageContacts: string = 'storageContacts';
const prerender=require('prerender-node');
// React.lazy(() => import("./components/Veranstaltungsübersicht_Anon"));
export default function App() {
  const [metaData, setMetaData] = React.useState({title:"wvib Schwarzwald AG",description:"wvib Schwarzwald AG Description",url:"https://wvib.de/",image:"/assets/News/previewLogo_2021_wvib_Schwarzwald_AG.png"});
  // React.useEffect(() => {
  //  var client = redis.createClient();
  //  prerender.set('beforeRender', function(req: { url: any; }, done: any) {
  //   client.get(req.url, done);
  // }).set('afterRender', function(err: any, req: { url: any; }, prerender_res: { body: any; }) {
  //   client.set(req.url, prerender_res.body)
  // });
  // });
  React.useEffect(() => {
    prerender.set();
    var currStorage= storage.session.get(storageContacts);
    if(currStorage==null)
    {
     azurecon.getData().then((result:any)=>{
        result.forEach((element:any)=> {
        });
      });
    }
  });

 return (
 //  <HashRouter hashType='hashbang'>
     <BrowserRouter basename="/" forceRefresh={true}> 
          <Helmet>
          <script src="https://www.googletagmanager.com/gtag/js?id=G-4TFTXC50H4" type="text/javascript" async></script>
          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="9698fcf8-9bab-40ee-86c3-e4c97bbe66a9" type="text/javascript" async></script>
        </Helmet>
         <div className="coockieBot-script">
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/9698fcf8-9bab-40ee-86c3-e4c97bbe66a9/cd.js " type="text/javascript" async></script>
         </div>  
         <Helmet>
          <title>{metaData.title}</title>
          <meta  name="description" content="Die wvib Schwarzwald AG ist Plattform für People, Planet, Progress im familiengeprägten, industriellen Mittelstand in Baden-Württemberg." data-rh="true"/>
           {/* <link rel="canonical" href={metaData.url}/>  */}
          <meta name="twitter:url" content={metaData.url}/> 
          <meta name="twitter:image" content={"https://wvib.de"+metaData.image}/>
          <meta name="twitter:description" content={metaData.description}/>
          <meta name="twitter:title" content={metaData.title}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@wvib" />
          <meta property="og:url" content={metaData.url}/>
          <meta property="og:site_name" content={metaData.title} />
          <meta property="og:image" content={"https://wvib.de"+metaData.image+'?w=800'}/>
          <meta property="og:description" content={metaData.description}/> /
          <meta property="og:title" content={metaData.title}/>
        </Helmet>
         <Helmet>
          <link rel="canonical" href={metaData.url}/>
         </Helmet> 
      <Switch>
        <Route exact path="/" component={Home} />
        <Route  path="/information/:id/:term">
          <Information onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/> 
      </Route>
      <Route  path="/information/:id/">
          <Information onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/> 
      </Route>
      <Route  path="/posts/:termId">
          <Posts onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/> 
      </Route>
      <Route  path="/TestSeite">
          <TestSeite onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/> 
      </Route>
      <Route  path="/search/:queryTxt">
          <Search /> 
      </Route>
      <Route path="/pages/:pageName" render={(routeProps) => (
            <Pages
              {...routeProps}
              onChangeHeader={(metaData:any) => {
                setMetaData(metaData);
              }}
            />
          )}
        />
      <Route path="/Veranstaltungsübersicht">
        <Veranstaltungsübersicht onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/>
        </Route>
        <Route path="/Veranstaltungsdetails">
          <Veranstaltungsdetails onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/>
          </Route>
        {/* <Route path="/Veranstaltungsdetails_Cluster">
          <Veranstaltungsdetails_Cluster onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/>
          </Route> */}
        <Route path="/AnlageNeuerBenutzer">
          <AnlageNeuerBenutzer description="" origin="" veranstaltung={null} closeModal={null}/>
        </Route>
        <Route path="/Seminarfinder" >
            <StartseiteAkademie description="" onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/>
        </Route>
        <Route path="/Veranstaltungsdetails_Cluster">
          <VeranstaltungsdetailsCluster onChangeHeader={(metaData:any)=>{
            setMetaData(metaData)
          }}/>
          </Route> 
        <Route path="/EventsCardBCTEST">
          <EventsCardbc/>
        </Route> 
      </Switch>
      <ScrollTopArrow />
      </BrowserRouter>       
 );
}
