import * as React from 'react';
// import { useHistory } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Dropdown } from 'react-bootstrap';
export default function Header() {
  //let history = useHistory();
  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-dark place-holder">
      <div className="container-fluid">
        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href='/' onClick={() => window.open('/','_self')} >Home</a>
            </li>
            <li className="nav-item">
              {/* <div className="nav-link">Der wvib</div> */}
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                wvib Schwarzwald AG
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-lg-4 col-sm-12  nav-col">
                          <h6>Das Unternehmernetzwerk</h6>
                          <Dropdown.Item href='/Pages/DiewvibSchwarzwaldAG.aspx' onClick={(e) => window.open('/Pages/DiewvibSchwarzwaldAG.aspx','_self')}>wvib Schwarzwald AG</Dropdown.Item>
                          <Dropdown.Item href='/Pages/Positionen.aspx' onClick={(e) => window.open('/Pages/Positionen.aspx','_self')}>Unsere Werte</Dropdown.Item>
                          <Dropdown.Item href='/Pages/wvibPrsident.aspx' onClick={(e) => window.open('/Pages/wvibPrsident.aspx','_self')}>Präsident</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DieGremiendeswvib.aspx' onClick={(e) => window.open('/Pages/DieGremiendeswvib.aspx','_self')}>Präsidium, Vorstand, Beirat</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DieHauptgeschftsfhrung.aspx' onClick={(e) => window.open('/Pages/DieHauptgeschftsfhrung.aspx','_self')}>Hauptgeschäftsführung</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DaswvibTeam.aspx' onClick={(e) => window.open('/Pages/DaswvibTeam.aspx','_self')}>wvib-Team</Dropdown.Item>
                          <Dropdown.Item href='/Pages/Stellenangebotewvib.aspx' onClick={(e) => window.open('/Pages/Stellenangebotewvib.aspx','_self')}>Karriere im wvib</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DieChronikderwvibimWandelderZeiten.aspx' onClick={(e) => window.open('/Pages/DieChronikderwvibimWandelderZeiten.aspx','_self')}>wvib - seit 1946</Dropdown.Item>
                          {/* <Dropdown.Item href="https://wvibde.sharepoint.com/sites/WVIBWeb/SitePages/Die-Chronik---der-wvib-im-Wandel-der-Zeiten.aspx">Der wvib im Wandel der Zeit</Dropdown.Item> */}
                        </div>
                        <div className="col-lg-4 col-sm-12 nav-col">
                        <h6>Members only</h6>
                        <Dropdown.Item href='/Pages/Mitgliedsunternehmen.aspx' onClick={(e) => window.open('/Pages/Mitgliedsunternehmen.aspx','_self')}>Mitgliedsunternehmen</Dropdown.Item> 
                        <Dropdown.Item href='/Pages/ChefErfaDerErfahrungsaustauschderChefs.aspx' onClick={(e) => window.open('/Pages/ChefErfaDerErfahrungsaustauschderChefs.aspx','_self')}>Der Chef-Erfahrungsaustausch</Dropdown.Item>
                        <Dropdown.Item href='/Pages/Chefsache.aspx' onClick={(e) => window.open('/Pages/Chefsache.aspx','_self')}>Chefsache</Dropdown.Item>
                        <Dropdown.Item href='/Pages/Jahreshauptversammlung.aspx' onClick={(e) => window.open('/Pages/Jahreshauptversammlung.aspx','_self')}>Jahreshauptversammlung</Dropdown.Item>
                        <Dropdown.Item href='/Pages/Pssst.aspx' onClick={(e) => window.open('/Pages/Pssst.aspx','_self')}>Pssst</Dropdown.Item>
                        <Dropdown.Item href='/Pages/mywvibMitgliederportal.aspx' onClick={(e) => window.open('/Pages/mywvibMitgliederportal.aspx','_self')}>Mitgliederportal mywvib</Dropdown.Item>
                        <Dropdown.Item href='/Pages/wvibimDialog.aspx' onClick={(e) => window.open('/Pages/wvibimDialog.aspx','_self')}>wvib im Dialog</Dropdown.Item>
                        <Dropdown.Item href='/Pages/DieFrhlingsMatine.aspx' onClick={(e) => window.open('/Pages/DieFrhlingsMatine.aspx','_self')}>Frühlings-Matinée</Dropdown.Item>
                        <Dropdown.Item href='/information/wvibGehaltPlus' onClick={(e) => window.open('/information/wvibGehaltPlus','_self')}>wvib GehaltPlus</Dropdown.Item>
                        <Dropdown.Item href='/Pages/Mitgliedwerden.aspx' onClick={(e) => window.open('/Pages/Mitgliedwerden.aspx','_self')}>Werden Sie Mitglied!</Dropdown.Item>
                      </div>
                        <div className="col-lg-4 col-sm-12 nav-col">
                          <h6>Aktuelles</h6>
                          <Dropdown.Item href='/Pages/Blog.aspx' onClick={(e) => window.open('/Pages/Blog.aspx','_self')}>Mit spitzer Feder</Dropdown.Item>
                          <Dropdown.Item href='/Pages/WissenundWrme.aspx' onClick={(e) => window.open('/Pages/WissenundWrme.aspx','_self')}>Monatsmagazin "Wissen & Wärme"</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/Pages/Neues-aus-der-Schwarzwald-AG.aspx','_self')}>Neues aus der Schwarzwald AG</Dropdown.Item> */}
                          <Dropdown.Item href='/Pages/Kampagnen.aspx' onClick={(e) => window.open('/Pages/Kampagnen.aspx','_self')}>Kampagnen</Dropdown.Item>
                          <Dropdown.Item href='/Pages/75JahreZukunft.aspx' onClick={(e) => window.open('/Pages/75JahreZukunft.aspx','_self')}>75 Jahre Zukunft</Dropdown.Item>
                         
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/Pages/Mitgliedsunternehmen.aspx','_self')}>"Werden Sie Mitglied"</Dropdown.Item> */}
                        </div>
                      
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                Cluster
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-lg-4 col-sm-12 nav-col">
                          <h6>Branchencluster</h6>
                          <Dropdown.Item href='/posts/Automotive' onClick={(e) => window.open('/posts/Automotive','_self')}>Automotive</Dropdown.Item>
                          <Dropdown.Item href='/posts/Maschinenbau' onClick={(e) => window.open('/posts/Maschinenbau','_self')}>Maschinenbau</Dropdown.Item>
                          <Dropdown.Item href='/posts/Medizintechnik' onClick={(e) => window.open('/posts/Medizintechnik','_self')}>Medizintechnik</Dropdown.Item>
                        </div>
                        <div className="col-lg-4 col-sm-12 nav-col">
                          <h6>Unternehmenscluster</h6>
                          <Dropdown.Item href='/posts/Familienunternehmen' onClick={(e) => window.open('/posts/Familienunternehmen','_self')}>Familienunternehmen</Dropdown.Item>
                          <Dropdown.Item href='/posts/KMU' onClick={(e) => window.open('/posts/KMU','_self')}>KMU</Dropdown.Item>
                          <Dropdown.Item href='/posts/Private%20Equity' onClick={(e) => window.open('/posts/Private%20Equity','_self')}>Private Equity</Dropdown.Item>
                        </div>
                      <div className="col-lg-4 col-sm-12 nav-col">
                        <h6>Themencluster</h6>
                        <Dropdown.Item href='/posts/Globalisierung' onClick={(e) => window.open('/posts/Globalisierung','_self')}>Globalisierung</Dropdown.Item>
                        <Dropdown.Item href='/posts/Technologie' onClick={(e) => window.open('/posts/Technologie','_self')}>Technologie</Dropdown.Item>
                        <Dropdown.Item href='/posts/Nachhaltigkeit' onClick={(e) => window.open('/posts/Nachhaltigkeit','_self')}>Nachhaltigkeit</Dropdown.Item>
                        <Dropdown.Item href='/posts/Diversity' onClick={(e) => window.open('/posts/Diversity','_self')}>Diversity</Dropdown.Item>
                        <Dropdown.Item href='/posts/Politik' onClick={(e) => window.open('/posts/Politik','_self')}>Politik</Dropdown.Item>
                        
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
            <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                Szenen
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-lg-4 col-sm-12 nav-col">
                          <h6>Unternehmensführung</h6>
                          <Dropdown.Item href='/Pages/ChefErfaDerErfahrungsaustauschderChefs.aspx' onClick={(e) => window.open('/Pages/ChefErfaDerErfahrungsaustauschderChefs.aspx','_self')}>Chef-Erfahrungsaustausch</Dropdown.Item>
                          {/* <Dropdown.Item href='/posts/Familie, Eigentum' onClick={(e) => window.open('/posts/Familie, Eigentum','_self')}>Familie, Eigentum</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Strategie, Organisation' onClick={(e) => window.open('/posts/Strategie, Organisation','_self')}>Strategie, Organisation</Dropdown.Item>
                          <div className="nav-link">-</div>
                          <Dropdown.Item href='/Pages/Pssst.aspx' onClick={(e) => window.open('/Pages/Pssst.aspx','_self')}>Pssst! Kontakte nur für Mitglieder</Dropdown.Item>
                          <Dropdown.Item href="https://stellenmarkt.wvib.de/">wvib-Stellenmarkt</Dropdown.Item>
                        </div>
                        <div className="col-lg-4 col-sm-12 nav-col">
                          <h6>Fach- und Führungskräfte</h6>
                          <Dropdown.Item href="/Pages/DerFachErfahrungsaustausch.aspx"  onClick={(e) => window.open('/Pages/DerFachErfahrungsaustausch.aspx','_self')}>Fach-Erfahrungsaustausch</Dropdown.Item>
                          <Dropdown.Item href='/posts/Forschung und Entwicklung, Konstruktion' onClick={(e) => window.open('/posts/Forschung und Entwicklung, Konstruktion','_self')}>Forschung und Entwicklung, Konstruktion</Dropdown.Item>
                          <Dropdown.Item href='/posts/Einkauf, Supply Chain' onClick={(e) => window.open('/posts/Einkauf, Supply Chain','_self')}>Einkauf, Supply Chain</Dropdown.Item>
                          <Dropdown.Item href='/posts/Produktion' onClick={(e) => window.open('/posts/Produktion','_self')}>Produktion</Dropdown.Item>
                          <Dropdown.Item href='/posts/Qualitätsmanagement, Umwelt, Arbeitssicherheit' onClick={(e) => window.open('/posts/Qualitätsmanagement, Umwelt, Arbeitssicherheit','_self')}>Qualitätsmanagement, Umwelt, Arbeitssicherheit</Dropdown.Item>
                          <Dropdown.Item href='/posts/Marketing, Produktmanagement' onClick={(e) => window.open('/posts/Marketing, Produktmanagement','_self')}>Marketing, Produktmanagement</Dropdown.Item>
                          <Dropdown.Item href='/posts/Vertrieb, Service' onClick={(e) => window.open('/posts/Vertrieb, Service','_self')}>Vertrieb, Service</Dropdown.Item>
                          <Dropdown.Item href='/posts/Außenwirtschaft,%20Zoll' onClick={(e) => window.open('/posts/Außenwirtschaft,%20Zoll','_self')}>Außenwirtschaft, Zoll</Dropdown.Item>
                          <Dropdown.Item href='/posts/Finanzen, Controlling' onClick={(e) => window.open('/posts/Finanzen, Controlling','_self')}>Finanzen, Controlling</Dropdown.Item>
                          <Dropdown.Item href='/posts/Personalmanagement, Personalentwicklung' onClick={(e) => window.open('/posts/Personalmanagement, Personalentwicklung','_self')}>Personalmanagement, Personalentwicklung</Dropdown.Item>
                          <Dropdown.Item href='/posts/Informationstechnologie' onClick={(e) => window.open('/posts/Informationstechnologie','_self')}>Informationstechnologie</Dropdown.Item>
                          <Dropdown.Item href='/posts/Recht, Datenschutz, Steuern' onClick={(e) => window.open('/posts/Recht, Datenschutz, Steuern','_self')}>Recht, Datenschutz, Steuern</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Forschung, Entwicklung, Konstruktion','_self')}>Forschung, Entwicklung, Konstruktion</Dropdown.Item> */}
                          
                          
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Projektmanagament, Prozessmanagament','_self')}>Projektmanagament, Prozessmanagament</Dropdown.Item> */}
                          
                          
                      </div>
                      <div className="col-lg-4 col-sm-12 nav-col">
                        <h6>Methoden, Skills</h6>
                        {/* <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Vertrieb, Service','_self')}>Vertrieb, Service</Dropdown.Item> */}
                        <Dropdown.Item href='/posts/Mitarbeiterführung, Methoden, Skills' onClick={(e) => window.open('/posts/Mitarbeiterführung, Methoden, Skills','_self')}>Mitarbeiterführung, Methoden, Skills</Dropdown.Item>
                        <Dropdown.Item href='/posts/Projektmanagement, Prozessmanagement' onClick={(e) => window.open('/posts/Projektmanagement, Prozessmanagement','_self')}>Projektmanagement, Prozessmanagement</Dropdown.Item>
                        <Dropdown.Item href='/posts/Lean, Intralogistik' onClick={(e) => window.open('/posts/Lean, Intralogistik','_self')}>Lean, Intralogistik</Dropdown.Item>
                      </div>
                  </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
            <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                  Relationship Management
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container container-topNav">
                    <div className="nav-item-row">
                        <div className="col-lg-3 col-sm-12 nav-col">
                          <h6>wvib in der Region</h6>
                          <Dropdown.Item href='/Pages/DerVerbanddervorbeikommt.aspx' onClick={(e) => window.open('/Pages/DerVerbanddervorbeikommt.aspx','_self')}>Der Verband, der vorbei kommt</Dropdown.Item>
                          <Dropdown.Item href='/Pages/Unternehmergesprche.aspx' onClick={(e) => window.open('/Pages/Unternehmergesprche.aspx','_self')}>Unternehmergespräche</Dropdown.Item>
                        </div>
                        <div className="col-lg-3 col-sm-12 nav-col">
                          <h6>A-E</h6>
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Alb-Donau-Kreis','_self')}>Alb-Donau-Kreis</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Biberach','_self')}>Biberach</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Baden-Baden' onClick={(e) => window.open('/posts/Baden-Baden','_self')}>Baden-Baden</Dropdown.Item>
                          <Dropdown.Item href='/posts/Böblingen' onClick={(e) => window.open('/posts/Böblingen','_self')}>Böblingen</Dropdown.Item>
                          <Dropdown.Item href='/posts/Bodenseekreis' onClick={(e) => window.open('/posts/Bodenseekreis','_self')}>Bodenseekreis</Dropdown.Item>
                          <Dropdown.Item href='/posts/Breisgau-Hochschwarzwald' onClick={(e) => window.open('/posts/Breisgau-Hochschwarzwald','_self')}>Breisgau-Hochschwarzwald</Dropdown.Item>
                          <Dropdown.Item href='/posts/Calw' onClick={(e) => window.open('/posts/Calw','_self')}>Calw</Dropdown.Item>
                          <Dropdown.Item href='/posts/Emmendingen' onClick={(e) => window.open('/posts/Emmendingen','_self')}>Emmendingen</Dropdown.Item>
                          <Dropdown.Item href='/posts/Enzkreis, Pforzheim' onClick={(e) => window.open('/posts/Enzkreis, Pforzheim','_self')}>Enzkreis, Pforzheim</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) => window.open('/posts/Esslingen','_self')}>Esslingen</Dropdown.Item> */}
                          
                      </div>
                      <div className="col-lg-3 col-sm-12 nav-col">
                          <h6>F-P</h6>
                          <Dropdown.Item href='/posts/Freiburg im Breisgau' onClick={(e) => window.open('/posts/Freiburg im Breisgau','_self')}>Freiburg im Breisgau</Dropdown.Item>
                          <Dropdown.Item href='/posts/Freudenstadt' onClick={(e) =>window.open('/#posts/Freudenstadt','_self')}>Freudenstadt</Dropdown.Item>
                         {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Germersheim')}>Germersheim</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Göppingen')}>Göppingen</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Heidelberg')}>Heidelberg</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Heidenheim')}>Heidenheim</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Hohenlohekreis')}>Hohenlohekreis</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Karlsruhe' onClick={(e) =>window.open('/#posts/Karlsruhe','_self')}>Karlsruhe</Dropdown.Item>
                          <Dropdown.Item href='/posts/Konstanz' onClick={(e) =>window.open('/#posts/Konstanz','_self')}>Konstanz</Dropdown.Item>
                          <Dropdown.Item href='/posts/Lörrach' onClick={(e) =>window.open('/#posts/Lörrach','_self')}>Lörrach</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Ludwigsburg')}>Ludwigsburg</Dropdown.Item> */}
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Neckar-Odenwald')}>Neckar-Odenwald</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Ortenaukreis' onClick={(e) =>window.open('/#posts/Ortenaukreis','_self')}>Ortenaukreis</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Pforzheim')}>Pforzheim</Dropdown.Item> */}
                      </div>
                      <div className="col-lg-3 col-sm-12 nav-col">
                          <h6>R-Z</h6>
                          <Dropdown.Item href='/posts/Rastatt' onClick={(e) =>window.open('/#posts/Rastatt','_self')}>Rastatt</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Böblingen')}>Ravensburg</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Bodenseekreis')}>Rems-Murr-Kreis</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Breisgau-Hochschwarzwald')}>Reutlingen</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Calw')}>Rhein-Neckar-Kreis</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Rottweil' onClick={(e) =>window.open('/#posts/Rottweil','_self')}>Rottweil</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Neckar-Odenwald')}>Neckar-Odenwald</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Esslingen')}>Esslingen</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Schwarzwald-Baar' onClick={(e) =>window.open('/#posts/Schwarzwald-Baar','_self')}>Schwarzwald-Baar</Dropdown.Item>
                          {/* <Dropdown.Item href='#' onClick={(e) =>window.open('/#posts/Freudenstadt')}>Sigmaringen</Dropdown.Item> */}
                          <Dropdown.Item href='/posts/Stuttgart, Esslingen, Ludwigsburg#' onClick={(e) =>window.open('/#posts/Stuttgart, Esslingen, Ludwigsburg','_self')}>Stuttgart, Esslingen, Ludwigsburg</Dropdown.Item>
                          <Dropdown.Item href='/posts/Tuttlingen' onClick={(e) =>window.open('/#posts/Tuttlingen','_self')}>Tuttlingen</Dropdown.Item>
                          <Dropdown.Item href='/posts/Waldshut' onClick={(e) =>window.open('/#posts/Waldshut','_self')}>Waldshut</Dropdown.Item>
                      </div>
                  </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                <span className="glyphicon glyphicon-menu-down">Akademie</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-md-6 nav-col">
                          <h6>Praxisnahe Weiterbildung</h6>
                          <Dropdown.Item href="/Seminarfinder">Seminarfinder</Dropdown.Item>
                          <Dropdown.Item href='/Pages/LehrgngeinderwvibAkademie.aspx' onClick={(e) => window.open('/Pages/LehrgngeinderwvibAkademie.aspx','_self')}>Lehrgänge</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DiewvibAkademiedievorbeikommtInhouse.aspx' onClick={(e) => window.open('/Pages/DiewvibAkademiedievorbeikommtInhouse.aspx','_self')}>Inhouse</Dropdown.Item>
                          <Dropdown.Item href='/Pages/Events.aspx' onClick={(e) => window.open('/Pages/Events.aspx','_self')}>Events</Dropdown.Item>
                        </div>
                        <div className="col-md-6 nav-col">
                          <h6>Über uns</h6>
                          <Dropdown.Item href='/Pages/DiewvibAkademie.aspx' onClick={(e) => window.open('/Pages/DiewvibAkademie1.aspx','_self')}>wvib-Akademie</Dropdown.Item>
                          <Dropdown.Item href='/Pages/AnsprechpartnerAkademie.aspx' onClick={(e) => window.open('/Pages/AnsprechpartnerAkademie.aspx','_self')}>Ansprechpartner</Dropdown.Item>
                          <Dropdown.Item href='/Pages/DozenteninderwvibAkademie.aspx' onClick={(e) => window.open('/Pages/DozenteninderwvibAkademie.aspx','_self')}>Dozenten</Dropdown.Item>
                          <Dropdown.Item href='/Pages/AnfahrtwvibAkademie.aspx' onClick={(e) => window.open('/Pages/AnfahrtwvibAkademie.aspx','_self')}>Standorte und Anfahrt</Dropdown.Item>
                          <Dropdown.Item href='/Pages/AGBder.aspx' onClick={(e) => window.open('/Pages/AGBder.aspx','_self')}>AGB</Dropdown.Item>
                        </div>      
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/* <li className="nav-item">
            <a href='/Pages/Legal-Service.aspx' className="nav-link"  onClick={(e) => window.open('/Pages/Legal-Service.aspx','_self')} >Legal Service</a>
            </li> */}
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                <span className="glyphicon glyphicon-menu-down">Legal Service</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-md-12 nav-col">
                          <Dropdown.Item href='/Pages/LegalService.aspx' onClick={(e) => window.open('/Pages/LegalService.aspx','_self')}>Legal Desk</Dropdown.Item>
                          <Dropdown.Item href='/Pages/HinweisgeberService.aspx' onClick={(e) => window.open('/Pages/HinweisgeberService.aspx','_self')}>Hinweisgeber-Service</Dropdown.Item>
                        </div>      
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                <span className="glyphicon glyphicon-menu-down">Mediencenter</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="container">
                    <div className="nav-item-row">
                        <div className="col-md-6 nav-col lang-col">
                          <h6>Nachrichten</h6>
                          <Dropdown.Item href='/Pages/Pressemeldungendeswvib.aspx' onClick={(e) => window.open('/Pages/Pressemeldungendeswvib.aspx','_self')}>Pressemeldungen wvib</Dropdown.Item>
                          <Dropdown.Item href='/Pages/PressemeldungenderMitglieder.aspx' onClick={(e) => window.open('/Pages/PressemeldungenderMitglieder.aspx','_self')}>Pressemeldungen der Mitglieder</Dropdown.Item>
                          <Dropdown.Item href='/Pages/Interviewrunden.aspx' onClick={(e) => window.open('/Pages/Interviewrunden.aspx','_self')}>Interviewrunden</Dropdown.Item>
                          <Dropdown.Item href='/Pages/NeuesausderSchwarzwald-AG.aspx' onClick={(e) => window.open('/Pages/NeuesausderSchwarzwaldAG.aspx','_self')}>Newsfeed</Dropdown.Item>
                        </div>
                        <div className="col-md-6 nav-col">
                          <h6>Downloads</h6>
                          <Dropdown.Item href='/Pages/Pressefotos.aspx' onClick={(e) => window.open('/Pages/Pressefotos.aspx','_self')}>Pressefotos</Dropdown.Item>
                          <Dropdown.Item href='/Pages/LogosderwvibSchwarzwaldAG.aspx' onClick={(e) => window.open('/Pages/LogosderwvibSchwarzwaldAG.aspx','_self')}>Logos</Dropdown.Item>
                        </div>      
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/* <li className="nav-item">
            </li> */}
            <li className="nav-item">
              {/* <div className="nav-link" href='#' onClick={() => window.open('https://wvibde.sharepoint.com/sites/WVIBWeb','_self')} >Login</div> */}
            </li>
            
          </ul>
        </div>
      </div>
    </nav>)
}