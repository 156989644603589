import * as React from 'react';

import { TextField} from 'office-ui-fabric-react/lib/TextField';
//require('../css/bootstrap.css');
//require('../css/bootstrap-grid.css');
// require('../css/AkademieStyles.css');
export interface IRechnungComponentProps {
  description: string;
  rechnungData: iRechnungdaten;
}

export type iRechnungdaten = {
  Address:string,
  Address_2:string,
  Post_Code:string,
  City:string,
  Company_Name:string,
  Bill_to_E_Mail:string,
  Bill_to_Internal_No:string,
  Bill_to_as_E_Mail:boolean
};

export default function RechnungComponent(props: IRechnungComponentProps) {
  const [rechnungData, setRechnungData] = React.useState<iRechnungdaten>(null);
  const [emailText, setEmailText] = React.useState<string>("E-Mail ");
  const [emailBool, setEmailBool] = React.useState<boolean>(props.rechnungData.Bill_to_as_E_Mail);
 
  let quarterTD:React.CSSProperties = {
    width: '25%'
  }

  let userTD:React.CSSProperties = {
    padding: '1%'
  }

  let userTable:React.CSSProperties = {
    width: '100%',
    backgroundColor: '#F1F1F1'
  }

  React.useEffect(() => {
    if(emailBool)
    {
      setEmailText("E-Mail *");
    }
    else
    {
      setEmailText("E-Mail ");
    }
  },[emailBool]);

  React.useEffect(() => {
    setRechnungData(InitialRechnungData());
  },[]);

  if(!rechnungData)
  {
    return <></>;
  }

  return(
    <div className={"CommonDivContainer"}>
      <table style={userTable}>
        <tr>
          <td style={quarterTD}></td>
          <td style={quarterTD}></td>
          <td style={quarterTD}></td>
          <td style={quarterTD}></td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"rechnungUnternehmen"} value={rechnungData.Company_Name} label="Unternehmen *"
            onChange={(e, newValue) => {
              const tmpRechnung = {...rechnungData};
              tmpRechnung.Company_Name = newValue;
              setRechnungData(tmpRechnung);
              props.rechnungData.Company_Name = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"rechnungNameStr"} value={rechnungData.Address} label="Straße und Hausnummer *"
            onChange={(e, newValue) => {
              const tmpRechnung = {...rechnungData};
              tmpRechnung.Address = newValue;
              setRechnungData(tmpRechnung);
              props.rechnungData.Address = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={1}>
            <TextField id={"rechnungNamePlz"} value={rechnungData.Post_Code} label="PLZ *"
            onChange={(e, newValue) => {
              const tmpRechnung = {...rechnungData};
              tmpRechnung.Post_Code = newValue;
              setRechnungData(tmpRechnung);
              props.rechnungData.Post_Code = newValue;
            }}
            />
          </td>
          <td style={userTD} colSpan={3}>
          <TextField id={"rechnungNameOrt"} value={rechnungData.City} label="Ort *"
            onChange={(e, newValue) => {
              const tmpRechnung = {...rechnungData};
              tmpRechnung.City = newValue;
              setRechnungData(tmpRechnung);
              props.rechnungData.City = newValue;
            }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"rechnungAdressZusatz"} value={rechnungData.Address_2} label="Adresszusatz"
              onChange={(e, newValue) => {
                const tmpRechnung = {...rechnungData};
                tmpRechnung.Address_2 = newValue;
                setRechnungData(tmpRechnung);
                props.rechnungData.Address_2 = newValue;
              }}
            />
          </td>
        </tr>
        <tr>
          <div className="datenschutz-box">
          <input type="checkbox" className="mailCB" checked={rechnungData.Bill_to_as_E_Mail} onChange={() => {
                const tmpRechnung = {...rechnungData};
                tmpRechnung.Bill_to_as_E_Mail = !rechnungData.Bill_to_as_E_Mail;
                setRechnungData(tmpRechnung);
                setEmailBool(!rechnungData.Bill_to_as_E_Mail);
                props.rechnungData.Bill_to_as_E_Mail = !rechnungData.Bill_to_as_E_Mail;
              }} />Rechnung per E-Mail 
            {/* <Checkbox label="Rechnung per E-Mail " checked={rechnungData.wantsMail} onChange={(e, checked) => {
              const tmpRechnung = {...rechnungData};
              tmpRechnung.wantsMail = checked;
              setRechnungData(tmpRechnung);
              props.rechnungData.wantsMail = checked;
            }} /> */}
            </div>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"rechnungEmail"} value={rechnungData.Bill_to_E_Mail} label={emailText}
              onChange={(e, newValue) => {
                const tmpRechnung = {...rechnungData};
                tmpRechnung.Bill_to_E_Mail = newValue.trim();
                setRechnungData(tmpRechnung);
                props.rechnungData.Bill_to_E_Mail = newValue.trim();
              }}
            />
          </td>
        </tr>
        <tr>
          <td style={userTD} colSpan={4}>
            <TextField id={"rechnungFirmenBestllnummer"} value={rechnungData.Bill_to_Internal_No} label="Firmeninterne Bestellnummer"
              onChange={(e, newValue) => {
                const tmpRechnung = {...rechnungData};
                tmpRechnung.Bill_to_Internal_No = newValue;
                setRechnungData(tmpRechnung);
                props.rechnungData.Bill_to_Internal_No = newValue;
              }}
            />
          </td>
        </tr>
      </table>
    </div>
  );

  function InitialRechnungData()
  {
    var emptyDummyJson:string = '{"Company_Name":"","City": "","Post_Code": "","Address":"", "Address_2": "","Bill_to_E_Mail":"", "Bill_to_Internal_No":"","Bill_to_as_E_Mail":false}';
    var jsonData:iRechnungdaten = JSON.parse(emptyDummyJson);
    return jsonData;
  }

}
