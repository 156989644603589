
import React, { useCallback } from 'react';
import ReactLoading from 'react-loading';
import '../css/custom-style.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { PnPClientStorage } from "@pnp/common";
import AzureConnector from '../Module/AzureConnector';
import moment from 'moment';
import 'moment/locale/de';
import placeHolder from '../images/registerformplaceholder.png';
import Registrierung from './Registrierung_Anon';
import GoogleAnalytics from './GoogleAnalytics';
import { useHistory } from "react-router-dom";
import { Document, Page,pdfjs} from "react-pdf";
import $ from 'jquery';
// import Flinque from './Flinque';
// import FsLightbox from 'fslightbox-react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const azurecon = new AzureConnector();
let result: any[] = [];
let contentHtml: any = null;
let headerHtml: any = null;
let currPageName: string;
let loading: boolean = false;
let imgTile: any = null;
let imgSrcWithCaption: any = null;
let internLinkMap = new Map();
let registerSeite: boolean = false;
const storage = new PnPClientStorage();
const storageContacts: string = 'storageContacts';
type iBenutzerdaten = {
  userKey: number,
  Salutation: string,
  Surname: string,
  First_Name: string,
  Address: string,
  Address_2: string,
  Post_Code: string,
  City: string,
  E_Mail: string,
  Phone_No: string,
  New_Company_Name: string,
  Event_No: string,
  No: string,
  Job_Title: string,
  Occupation: string,
  Leader: boolean,
  wantsToJoin_DUMMY: boolean,
  participant_DUMMY: any,
  Contact_Data_Entry_No: number
};
export const photos = [
  {
    src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    width: 1,
    height: 1
  },
  {
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4
  },
  {
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3
  }
];

export interface IPagesProps {
  onChangeHeader:any;
}
export default function Pages(props:IPagesProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var uData: iBenutzerdaten = { Salutation: '', New_Company_Name: '', E_Mail: '', City: '', Post_Code: '', Address: '', Address_2: '', Surname: '', Phone_No: '', First_Name: '', Event_No: '', userKey: 0, No: '', Job_Title: '', Occupation: '', Leader: false, wantsToJoin_DUMMY: false, participant_DUMMY: false, Contact_Data_Entry_No: 0 };
  React.useEffect(() => {

  }, []);

  let history = useHistory();
  const [numPages, setNumPages] =  React.useState(null);
  const [pageNumber, setPageNumber] =  React.useState(1);
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [newsData, setNews] = React.useState<any[]>([]);
  const [currentPageName, setcurrentPageName] = React.useState("");
  const [contatcsData, setcontatcsData] = React.useState<any[]>([]);
  const [staticpages, setStaticPages] = React.useState<any[]>([]);
  const { pageName }: any = useParams();
  const [currMetaData, setcurrMetaData] = React.useState(null);
  let htmlControls: any[] = [];
  
  let currShareUrl = window.location.href;
  
  // fetch("https://www.wvib.de/assets/json/static").then(res => res.json()).then(result => {
  //   console.log("results",result);
  // }).catch(console.log);
  function onDocumentLoadSuccess(numPages:any) {
    setNumPages(numPages);
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  React.useEffect(() => {
      $(function() {
        $('iframe').each(function(){
          function injectCSS(){
              $iframe.contents().find('head').append(
                  $('<link/>', { rel: 'stylesheet', href: 'https://www.wvib.de/assets/iframe.css', type: 'text/css' })
              );
              $iframe.contents().find('body').append(
                $('<link/>', { rel: 'stylesheet', href: 'https://www.wvib.de/assets/iframe.css', type: 'text/css' })
            );
          }
      
          var $iframe = $(this);
          $iframe.on('load', injectCSS);
          injectCSS();
      });
      });
    // });

  })
  React.useEffect(() => {
    //////console.log("before news azure call", new Date());
    let tmpNews: any[] = [];
      if(newsData.length===0)
      {
        fetch('/assets/json/news.json').then((res) => res.json())
        .then((jsonNewsData) => {
          jsonNewsData.forEach((element: any) => {
            tmpNews.push({ element });
          });
          tmpNews = tmpNews.sort((a, b) => b.publishedDate - a.publishedDate);
          setNews(tmpNews);
        });
      }
    let tmpStatics: any[] = [];
    if(staticpages.length===0)
    {
      fetch('/assets/json/static.json').then((res) => res.json())
      .then(function (jsonStaticData) {
        jsonStaticData.forEach((staticItem: any) => {
          console.log("staticITem",staticItem);
          tmpStatics.push(staticItem);
        });
        setStaticPages(tmpStatics);
      });
    
    }
    let storContacts = storage.session.get(storageContacts);
    if (storContacts != null && storContacts.length > 0) {
      //////console.log("sessio n contacts", new Date());
      setcontatcsData(storContacts);
    } else {
      azurecon.getContacts().then((result: any) => {
        let tmpStatics: any[] = [];
        result.forEach((element: any) => {
          tmpStatics.push(element);
        });
        //////console.log("After contact azure call", new Date());
        setcontatcsData(tmpStatics);
      });
    }
  }, [newsData, staticpages]);
  React.useEffect(() => {
    //////console.log("react set pagename");
    if ({ pageName } !== null) {
      currPageName = { pageName }.pageName;
      currPageName=decodeURIComponent(currPageName)
      if(currPageName.endsWith("/")){
        currPageName=currPageName.slice(0,-1);
      }
      currPageName=removeSpecialCharacters(currPageName);
      console.log("tmpcurrPageName",currPageName);
      setcurrentPageName(currPageName);
    }
  }, [pageName]);

  if (staticpages != null && staticpages.length > 0 && contatcsData != null && contatcsData.length > 0 && newsData !== null && newsData.length > 0) {
    //////console.log("befor calling GetcurretnStatic");
    GenerateContent();
    window.scrollTo(0, 0);
  } else {

  }
  function removeSpecialCharacters(title: string){
    const pattern: RegExp = /[^0-9a-zA-Z.]/g;
    if (pattern.test(title)) {
      var currNewsID = title.replace(pattern, '');
        history.push("/Pages/" + currNewsID);  
        window.location.reload();
      }
    return title.replace(pattern, '');
  }
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <main role="main">
        <div className="container-fluid page-container">
          <div className="row page-main" hidden={!loading}>
            <div className="col-md-12">
              {headerHtml}
              <div className="container-row static-content-txt ">
                {contentHtml}
              </div>
            </div>
          </div>
          <div className="row  loading-box" hidden={loading}>
            <ReactLoading color={"#293940"} width={175} type={"spin"} />
          </div>
          <GoogleAnalytics />
          {/* <Flinque /> */}
          <Footer />
        </div>
      </main>
    </div>
  );

  function GenerateContent() {
    result.length = 0;
    let translateX = 0;
    let translateY = 0;
    let headerImgExist = false;
    const map = new Map();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    staticpages.forEach((element, i) => {

      if (!map.has(element.id) && element.urltitle!== null && element.urltitle=== currentPageName) {
        if (currentPageName === "Registrierung.aspx") {
          registerSeite = true;
        }
        document.title = element.title;
        translateX = element.layoutWebpartsContent.translateX;
        translateY = element.layoutWebpartsContent.translateY;
        headerImgExist = element.layoutWebpartsContent.layoutType;
        map.set(element.id, true);    // set any value to Map
        // let headerImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
        let headerImgSrc = `${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
        if (element.imgSrc != null && headerImgExist && !registerSeite) {
          headerImgSrc = `${process.env.PUBLIC_URL}/assets/News/${element.imgSrc}`;
        }
        if(currMetaData===null){
          props.onChangeHeader({title:element.title,description:element.title,url:currShareUrl,image:headerImgSrc}); 
          setcurrMetaData({title:element.title,description:element.title,url:currShareUrl,image:headerImgSrc});
        }
        let currentCanvas = element.canvas;
        let webPartGuidID: any = null;
        let webPartMap = new Map();
        let quickLinkTitleMap = new Map();
        let zoneIndexList: any[] = [];

        let zoneMap = new Map();
        if (element.canvas != null) {
          //const json = parse(element.canvas);
          let zoneIndex: any = null;
          let sectionIndex: any = null;
          let controlIndex: any = null;
          let gesamtIndex = 0;
          let sectionFactor = "col-lg-12";
          let oriSectionFactor: any = null;
          let currIndex = 1;
          internLinkMap.clear();
          //////console.log("Parse",parse(element.canvas));
          //#region beginn of canvas Parse
          currentCanvas = parse(element.canvas, {

            // eslint-disable-next-line no-loop-func
            replace: ({ attribs, children }) => {

              if (attribs && typeof attribs["data-sp-controldata"] != "undefined") {
                gesamtIndex = 20;
                var controldatJson = JSON.parse(attribs["data-sp-controldata"]);
                if ((typeof controldatJson !== "undefined") && controldatJson !== null) {
                  if ((typeof controldatJson.id !== "undefined") && controldatJson.id !== null)
                    webPartGuidID = controldatJson.id;
                  let currPosition = controldatJson.position;
                  if ((typeof currPosition !== "undefined") && currPosition !== null) {
                    if ((typeof currPosition.zoneIndex !== "undefined") && currPosition.zoneIndex !== null) {
                      zoneIndex = currPosition.zoneIndex;
                      if (!zoneMap.has(zoneIndex)) {
                        zoneMap.set(zoneIndex, true);
                        zoneIndexList.push(zoneIndex);
                      }
                    }
                    if ((typeof currPosition.controlIndex !== "undefined") && currPosition.controlIndex !== null) {
                      controlIndex = currPosition.controlIndex;
                      gesamtIndex = controlIndex * 10;
                      gesamtIndex = Math.trunc(gesamtIndex);
                    }
                    if ((typeof currPosition.sectionIndex !== "undefined") && currPosition.sectionIndex !== null) {
                      sectionIndex = currPosition.sectionIndex;
                    }
                    if ((typeof currPosition.sectionFactor !== "undefined") && currPosition.sectionFactor !== null) {
                      oriSectionFactor = currPosition.sectionFactor;
                    }


                    if ((typeof currPosition.sectionFactor !== "undefined") && currPosition.sectionFactor !== null) {
                      switch (currPosition.sectionFactor) {
                        case 4:
                          sectionFactor = "col-sm-12 col-lg-4";
                          break;
                        case 8:
                          sectionFactor = "col-sm-12 col-lg-8";
                          break;
                        case 6:
                          sectionFactor = "col-sm-12 col-lg-6";
                          break;
                        case 12:
                          sectionFactor = "col-sm-12 center-box";
                          break;
                        default:
                          sectionFactor = "col-sm-12 col-lg-12";
                          break;
                      }
                    }
                  }
                }
              }
              if (attribs && (typeof attribs["data-sp-prop-name"] !== "undefined") && attribs["data-sp-searchableplaintext"] !== null) {
                var json1 = attribs["data-sp-prop-name"];
                var src = attribs["src"];
                if ((typeof json1 !== "undefined") && json1 !== null) {
                  //console.log("hie rin coi", json1);
                  if (json1 === "captionText" && typeof children !== "undefined" && children.length > 0) {
                    //console.log(" children", children);
                    // TODO: get Childrn Div
                    imgTile = domToReact(children);
                  }
                  else if (json1 === "imageSource" && src !== null) {
                    imgSrcWithCaption = src;
                    imgSrcWithCaption = _getImgUrl(src);
                    if (imgSrcWithCaption !== null && imgSrcWithCaption !== "") {
                      ////console.log(currImgSrc);
                      imgSrcWithCaption = `${process.env.PUBLIC_URL}/assets/News/${imgSrcWithCaption}`;
                    }
                  }

                }
              }
              if (attribs && (typeof attribs["data-sp-rte"] !== "undefined") && attribs["data-sp-rte"] !== null && (typeof (attribs["href"] === "undefined"))) {

                if (typeof children !== "undefined" && children.length > 0) {
                  if (children.length > 0) {
                    children = ChildrenNames(children);
                    // children.forEach((element)=>{
                    //     if(!(element.children))
                    //     ////console.log()
                    // });
                    // ////console.log(children.filter(child=> (typeof child.attribs !=="undefined") && (typeof child.attribs.href!=="undefined")).map(child=>child.attribs))
                    // // eslint-disable-next-line array-callback-return
                    // children.map(child=>{
                    //   if( typeof child.attribs!=="undefined" && (typeof child.attribs.href !=="undefined"))
                    //   return child.attribs.href === _getFileHtml(child.attribs.href);
                    // });
                  }
                  // ////console.log(domToReact(children));
                  if (!webPartMap.has(webPartGuidID)) {
                    webPartMap.set(webPartGuidID, true);
                    ////console.log("NOt exist");
                    htmlControls.push({
                      id: webPartGuidID,
                      gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                      sectionIndex: sectionIndex,
                      controlIndex: controlIndex,
                      sectionFactor: sectionFactor,
                      htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{domToReact(children)}</div>
                    });
                  }

                }
              }

              if (attribs && (typeof attribs["data-sp-webpartdata"] !== "undefined")) {
                if (typeof attribs["data-sp-webpartdata"] !== "undefined") {
                  var json = JSON.parse(attribs["data-sp-webpartdata"]);
                  if ((typeof json !== "undefined") && json !== null) {
                    let contactFields = json.properties.fields;
                    let listLayout = json.properties.choiceGroupWithCalloutValue;
                    let newsFilters = json.properties.terms;
                    let _descr= json.properties.description;
                    let emdedCode = json.properties.embedCode;
                    let propertiesiTems = json.properties.items;
                    let fielviewer=json.properties.filePicker;
                    
                    if ((typeof fielviewer !== "undefined") && fielviewer.length !== 0) {
                      let fileUrl = fielviewer.fileAbsoluteUrl;
                      let fileViewer=_getFileViewer(fileUrl);
                      if(fileUrl!==undefined &&fileUrl !==null&&fileUrl!=="")
                      {
                        if (!webPartMap.has(webPartGuidID)) {
                          webPartMap.set(webPartGuidID, true);
                          htmlControls.push({
                            id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                            sectionIndex: sectionIndex,
                            controlIndex: controlIndex,
                            sectionFactor: sectionFactor,
                            htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{fileViewer}</div>
                          });
                        }
                      }
                    }
                    // eslint-disable-next-line valid-typeof
                    if ((typeof contactFields !== "undefined") && contactFields.length !== 0 && contatcsData !== null && contatcsData.length > 0) {
                      let contactsWbpart = _getContactHtml(attribs["data-sp-webpartdata"]);
                      if (!webPartMap.has(webPartGuidID)) {
                        webPartMap.set(webPartGuidID, true);
                        htmlControls.push({
                          id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                          sectionIndex: sectionIndex,
                          controlIndex: controlIndex,
                          sectionFactor: sectionFactor,
                          htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{contactsWbpart}</div>
                        });
                      }
                    }
                    if ((typeof newsFilters !== "undefined") && newsFilters.length !== 0) {
                      let schlagWort = newsFilters[0].name;
                      if (newsFilters[0].name === "") {
                        schlagWort = newsFilters[1].name;
                      }
                      if (schlagWort !== "") {
                        ////console.log(schlagWort)
                        let newsWebpart = _getNewsHtml(schlagWort, listLayout,_descr);
                        ////console.log("newswebpart", newsWebpart);
                        if (newsWebpart !== null && newsWebpart.length !== 0 && !webPartMap.has(webPartGuidID)) {
                          ////console.log("newsFiltess", webPartGuidID);
                          webPartMap.set(webPartGuidID, true);
                          htmlControls.push({
                            id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                            sectionIndex: sectionIndex,
                            controlIndex: controlIndex,
                            sectionFactor: sectionFactor,
                            htmlInnerControls: <div style={{ order: gesamtIndex }} className={`section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{newsWebpart}</div>
                          });

                          ////console.log("news", htmlControls);

                        } else {
                          ////console.log("exist laready");
                        }
                      }
                    }

                    if ((typeof emdedCode !== "undefined") && emdedCode !== null) {
                      emdedCode = parse(emdedCode);
                      if (!webPartMap.has(webPartGuidID)) {
                        webPartMap.set(webPartGuidID, true);
                        htmlControls.push({
                          id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                          sectionIndex: sectionIndex,
                          controlIndex: controlIndex,
                          sectionFactor: sectionFactor,
                          htmlInnerControls: <div style={{ order: gesamtIndex }} className={`iframe-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{emdedCode}</div>
                        });
                      }
                    }
                    if ((typeof propertiesiTems === "undefined") && (typeof newsFilters === "undefined") && (typeof emdedCode === "undefined") && typeof (json.serverProcessedContent !== "undefined")) {
                      ////console.log("webpaert", webPartGuidID);
                      let pageUrlProp = json.serverProcessedContent.links;
                      //////console.log("webpaert",webPartGuidID);
                      //////console.log("currentLinks",pageUrlProp);

                      let plainTxtProp = json.serverProcessedContent.searchablePlainTexts;
                      
                      let imgSrcProp = json.serverProcessedContent.imageSources;
                      ////console.log("curentimage ", imgSrcProp);
                      if ((typeof plainTxtProp !== "undefined") && plainTxtProp.length !== 0 && (typeof pageUrlProp !== "undefined") && pageUrlProp.length !== 0 && (typeof imgSrcProp !== "undefined") && imgSrcProp.length !== 0) {
                        ////console.log("hier in propertiesiTem");
                        if (typeof plainTxtProp.captionText !== "undefined") {
                          imgTile = plainTxtProp.captionText;
                        } else {
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          imgTile = null;
                        }
                        let staticSiteWebpart = _getStaticHtml(json);
                        if (staticSiteWebpart !== null && staticSiteWebpart.length !== 0 && !webPartMap.has(webPartGuidID)) {
                          ////console.log("hier in propertiesiTems", webPartGuidID);
                          webPartMap.set(webPartGuidID, true);
                          htmlControls.push({
                            id: webPartGuidID, gesamtIndex: gesamtIndex, zoneindex: zoneIndex,
                            sectionIndex: sectionIndex,
                            controlIndex: controlIndex,
                            sectionFactor: sectionFactor,
                            htmlInnerControls: <div style={{ order: gesamtIndex }} className={`orisecfactor: ${oriSectionFactor} section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  static-intern-links`}>{staticSiteWebpart}</div>
                          });
                        }
                      }
                    }
                    if (typeof propertiesiTems !== "undefined" && typeof (json.serverProcessedContent !== "undefined")) {
                      let linksSrcProp = json.serverProcessedContent.links;
                      let titlePlainTxt = json.serverProcessedContent.searchablePlainTexts;
                      let title = "";
                      let quickLinksTitle = "";
                      ////console.log("title",title);
                      if (json.properties.dataProviderId === "QuickLinks") {
                        const obj = linksSrcProp;
                        const titlkObj = titlePlainTxt;
                        let currIndex = 0;
                        Object.keys(obj).forEach((e, i) => {
                          if (e !== undefined && e !== null) {
                            if (e.indexOf("sourceItem.url") > 0) {
                              ////console.log(`key=${e}  value=${obj[e]}`);
                              let currItemIndex = e.split('.');
                              if (currItemIndex !== undefined && currItemIndex) {
                                //console.log("index: ", currItemIndex[0]);
                                Object.keys(titlkObj).forEach((titleItem, i) => {
                                  if (titleItem === "title") {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    if (!(quickLinkTitleMap.has(titlkObj[titleItem]))) {
                                      quickLinkTitleMap.set(titlkObj[titleItem], true);
                                      quickLinksTitle = titlkObj[titleItem];
                                    } else {
                                      quickLinksTitle = "";
                                    }
                                  }
                                  let currTitleIndex = titleItem.toString().split('.');
                                  if (currTitleIndex[0] === currItemIndex[0]) {
                                    //console.log("Current Title", titlkObj[titleItem])
                                    title = titlkObj[titleItem];
                                    // return false;
                                  }

                                })
                              }
                              if (obj[e] !== undefined && obj[e] !== null) {
                                ////console.log("elemnt url",i);
                                var currentLink = obj[e];
                                var tmpName = currentLink;
                                var currLinktmp = decodeURIComponent(currentLink);
                                var webString = decodeURIComponent(currentLink).split('?');
                                if (webString !== null && webString.length > 0) {
                                  tmpName = webString[0].split("/").map((item: string) => item.trim());
                                  if (tmpName.length > 1) {
                                    tmpName = tmpName[tmpName.length - 1];
                                  }
                                } else {
                                  tmpName = currLinktmp.split("/").map((item: string) => item.trim());
                                  if (tmpName.length > 1) {
                                    tmpName = tmpName[tmpName.length - 1];
                                  }
                                }
                                if (obj[e] != null && (!internLinkMap.has(tmpName))) {
                                  internLinkMap.set(tmpName, true);
                                  if (webString !== null && webString.length > 0)
                                    currentLink = webString[0];
                                  let currlinkHtml = _getQuickLinks(currentLink, title, quickLinksTitle);
                                  if (currlinkHtml !== undefined && currlinkHtml !== null) {
                                    currIndex++;
                                    webPartGuidID = webPartGuidID + currIndex;
                                    if (!webPartMap.has(webPartGuidID)) {
                                      webPartMap.set(webPartGuidID, true);
                                      htmlControls.push({
                                        id: webPartGuidID, gesamtIndex: gesamtIndex,
                                        zoneindex: zoneIndex,
                                        sectionIndex: sectionIndex,
                                        controlIndex: controlIndex,
                                        sectionFactor: sectionFactor,
                                        htmlInnerControls: <div style={{ order: gesamtIndex }} className={`quiklinks-box doc-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{quickLinksTitle !== "" ? <h5 className='quicklinksTitle'>{quickLinksTitle}</h5> : ""}{currlinkHtml}</div>
                                      });
                                    }
                                  }

                                }
                              }
                            }
                          }

                        });

                      }
                    }
                  }
                }
              }
              if (attribs && typeof attribs["href"] !== "undefined" && attribs["href"] !== "" && attribs["data-sp-prop-name"] !== "linkUrl" && (!internLinkMap.has(attribs["href"]))) {
                ////console.log(attribs["href"]);
                var currTmpLink = decodeURIComponent(attribs["href"]);
                var tmpName = currTmpLink.split("/").map((item: string) => item.trim());
                if (tmpName.length > 1)
                  currTmpLink = tmpName[tmpName.length - 1];
                if (attribs.href != null && (!internLinkMap.has(currTmpLink)) && attribs.href.indexOf("forms.office.com") === 0) {
                  internLinkMap.set(currTmpLink, true);
                  currIndex++;
                  let currlinkHtml = _getFileHtml(attribs.href);
                  if (currlinkHtml != null) {
                    webPartGuidID = webPartGuidID + currIndex;
                    if (!webPartMap.has(webPartGuidID)) {
                      webPartMap.set(webPartGuidID, true);
                      htmlControls.push({
                        id: webPartGuidID, gesamtIndex: gesamtIndex,
                        zoneindex: zoneIndex,
                        sectionIndex: sectionIndex,
                        controlIndex: controlIndex,
                        sectionFactor: sectionFactor,
                        htmlInnerControls: <div style={{ order: gesamtIndex }} className={`doc-container section-${sectionIndex} order:${gesamtIndex} gesamtIndex:${gesamtIndex} zoneIndex : ${zoneIndex} controlIndex: ${controlIndex}  orisecfactor: ${oriSectionFactor}`}>{currlinkHtml}</div>
                      });
                    }
                  }
                }
              }
              return;
            }
          });
        }

        //#endregion end of Canvas Parse
        if (headerImgExist && !registerSeite) {
          headerHtml = (<div className="header-box" ><div className="header-inner" style={{ backgroundImage: "url('" + headerImgSrc + "')", backgroundPositionX: translateX + "%", backgroundPositionY: translateY + "%", backgroundSize: "cover" }}>
            <div className="header-overly" >
            </div>
          </div>
            <div className="container-row" >
              <div className="container-fluid canavas-box" >
                <div className="static-header-title">{element.title}</div>
              </div>
            </div>
          </div>
          );
        } else if (!registerSeite) {
          headerHtml = (<div className="header-box-title" ><div className="header-inner-title">
            <div className="container-fluid" >
              <div className="static-header-title just-Title">{element.title}</div>
            </div>
          </div>
          </div>)

        }
        let htmlMap = new Map();
        let htmlFinalMap = new Map();
        let tmpAtrray: any[] = [];
        if (htmlControls !== null && htmlControls.length > 0) {
          htmlControls = htmlControls.sort((a, b) => a.zoneindex - b.zoneindex);
          ////console.log("before foreach", htmlControls);
          htmlControls.forEach((element) => {
            if (typeof element.zoneindex !== "undefined" && element.zoneindex !== null) {
              ////console.log(element)
              if (!htmlMap.has(element.id)) {
                htmlMap.set(element.id, true);
                tmpAtrray.push(element);
              }
            }

          });
        }
        tmpAtrray = groupByMulti(tmpAtrray, ["zoneindex", "sectionIndex"]);
        let finalTmpArray: any[] = [];
        let tmpArraySOrt: any[] = [];
        if (tmpAtrray.length > 0) {
          let guidID: any = 0;
          var threeSection = "";
          if (tmpAtrray.length >= 3) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            threeSection = "threeSection";
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            threeSection = "";
          }
          tmpAtrray.forEach((element, i) => {
            guidID = i;
            element.forEach((interElement: any) => {
              guidID = guidID + "-" + interElement.id;
            });
            if (!htmlFinalMap.has(guidID)) {
              htmlFinalMap.set(guidID, true);
              finalTmpArray.push({ id: i, zoneindex: element.map((a: any) => a.zoneindex)[0], htmlInnerControls: <div style={{ display: 'flex', flexDirection: 'column' }} className={`webpart-Sectionstatic ${threeSection} ${element.map((a: any) => a.sectionFactor)}`}>{element.map((a: any) => a.htmlInnerControls)}</div> });
            }
          })
        }
        if (finalTmpArray.length > 0) {
          if (finalTmpArray.length > 1) {
            htmlMap.clear();
            finalTmpArray = groupByMulti(finalTmpArray, ["zoneindex"]);
            finalTmpArray = finalTmpArray.sort((a, b) => a.zoneindex - b.zoneindex);
            let ID: any = null;
            finalTmpArray.forEach((element: any) => {
              let indexZone: any = null;
              let fullWith = "";
              let tmpSectionHtml: any[] = [];
              if (element.length === 1) {
                fullWith = "row-fullWith";
              }
              element.forEach((e1: any) => {
                ID = ID + e1.id;
                indexZone = e1.zoneindex;
              });
              if (!htmlMap.has(ID)) {
                htmlMap.set(ID, true);
                tmpSectionHtml.push(element.filter((a: any) => a.zoneindex === indexZone).map((a: any) => a.htmlInnerControls));
              }
              tmpArraySOrt.push(<div className={`row webpart-row ${fullWith} row-${indexZone}`}>{tmpSectionHtml}</div>)
            });
          } else {

            tmpArraySOrt.push(<div className="">{finalTmpArray.map(a => a.htmlInnerControls)}</div>)
          }
        }
        contentHtml = <div className="container-fluid canavas-box">{tmpArraySOrt.map((item) => (item))}</div>;
        // contentHtml = <div className="container-fluid canavas-box">{currentCanvas}</div>;
        return;
      }
    });
    loading = true;
  }

  //#region  help methods to generate webparts element from Canvas

  function ChildrenNames(obj: any[]) {
    obj.forEach((subObj: any) => {
      if (typeof subObj.name !== "undefined" && subObj.name === "a") {
        if ((typeof subObj.attribs.href !== "undefined") && subObj.attribs.href !== "") {
          var currLinktmp = decodeURIComponent(subObj.attribs.href);
          var tmpName = currLinktmp.split("/").map((item: string) => item.trim());
          if (tmpName.length > 1)
            currLinktmp = tmpName[tmpName.length - 1];
          // 02.09.21 that cause WorngLink
          // if (!internLinkMap.has(currLinktmp)) {
          // internLinkMap.set(currLinktmp, true);
          subObj.attribs.href = _getInternLink(subObj.attribs.href);
          internLinkMap.set(subObj.attribs.href, true);
          // }

        }
      }
      else if (typeof subObj.children !== "undefined") {
        ChildrenNames(subObj.children);
      }
    });
    return obj
  }

  function groupByMulti(arr: any[], properties: any[]) {
    var groups = [];
    for (var i = 0, len = arr.length; i < len; i += 1) {
      var obj = arr[i];
      if (groups.length === 0) {
        groups.push([obj]);
      }
      else {
        var equalGroup = null;
        for (var a = 0, glen = groups.length; a < glen; a += 1) {
          var group = groups[a];
          var equal = true;
          var firstElement = group[0];
          // eslint-disable-next-line no-loop-func
          properties.forEach(function (property) {
            if (firstElement[property] !== obj[property]) {
              equal = false;
            }

          });
          if (equal) {
            equalGroup = group;
          }
        }
        if (equalGroup) {
          equalGroup.push(obj);
        }
        else {
          groups.push([obj]);
        }
      }
    }
    return groups;
  };
  function _getSiteUrl(_urlPath: any) {
    let pageUrl: any = _urlPath;
    let name: any = null;
    ////console.log("Fullname", fullName);
    ////console.log("sitepage", fullName.indexOf("/SitePages/"));
    if (_urlPath !== undefined && _urlPath !== "") {
      if (_urlPath !== null && _urlPath.indexOf("sites/WVIB_Akademie/") > 0) {
        let _tmpUrl = _urlPath.split('#');
        // pageUrl = "/AnonEvents.html#" + _tmpUrl[1];
        pageUrl = "/Seminarfinder" + _tmpUrl[1];
      } else
        if (_urlPath.indexOf("www.wvib.de") > -1 || _urlPath.toLowerCase().indexOf("/sitepages/") === -1) {
          if (_urlPath.indexOf(".pdf") > -1) {
            let fileFullName = "";
            var file = _urlPath.toString().split("/").map((item: string) => item.trim());
            var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
            if (exst.length > 1) {
              fileFullName = file[file.length - 1];
              if (exst != null && exst.length > 0) {
                pageUrl = `${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`;
              }
            }
          }
          else {
            pageUrl = _urlPath;
          }
        }
        else {
          var tmpName = _urlPath.split("/").map((item: string) => item.trim());
          if (tmpName.length > 1) {
            name = tmpName[tmpName.length - 1];
            pageUrl = "/Pages/" + _createurltitle(name);
          }
        }
    }
    return pageUrl;
  }
  function _getImageSrc(fullName: any) {
    let name: any = null;
    var tmpName = fullName.split("/").map((item: string) => item.trim());
    //var exst = imgUrl.split("/").map((item: string) => item.trim());
    let currImgSrc: any = null;
    if (tmpName.length > 1) {
      name = tmpName[tmpName.length - 1];
      if (staticpages !== null && staticpages.length > 0) {
        staticpages.forEach((element, i) => {
          if (element.urltitle!== null && element.urltitle=== name) {
            currImgSrc = element.imgSrc;
            return;
          }
        });
      }
    }
    return currImgSrc;

  }

  function trunCat(desc: string, max: number) {
    if (desc != null && desc.length > 0) {
      var suffix = "...";
      desc = desc.length < max ? desc : `${desc.substr(0, desc.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    }
    return desc
  }
  function _getStaticHtml(_staticPageInfo: any) {
    let staticPageInfo =_staticPageInfo.serverProcessedContent;
    let _align:any =null;
    if(_staticPageInfo.properties!==undefined && _staticPageInfo.properties!=null)
    {
      _align=_staticPageInfo.properties.alignment;
    }
    let registerArea = false;
    ////console.log("Register: ", registerSeite);
    let currStaticpageItem: any = [];
    let title = "";
    let descr = "";
    let siteUrl = "";
    let labelTxt = "";
    let currImgSrc = "";
    let imageHtml: any = null;
    let galeryImageList: any = [];
    let Layout = "list";
    if (typeof staticPageInfo.customMetadata !== "undefined" && staticPageInfo.customMetadata !== "" && typeof staticPageInfo.customMetadata.imageSource !== "undefined" && staticPageInfo.customMetadata.imageSource !== "") {
      if (typeof staticPageInfo.customMetadata.imageSource.width !== "undefined" && staticPageInfo.customMetadata.imageSource.width !== "") {

      }
      Layout = "kachel";
    }
    if (typeof staticPageInfo.links.formURL !== "undefined" && staticPageInfo.links.formURL !== "") {
      return null;
    } else if (typeof staticPageInfo.links.url !== "undefined" && staticPageInfo.links.url !== "") {

      var tmpName = staticPageInfo.links.url.split("/").map((item: string) => item.trim());
      var currTmpLink: any = null;
      if (tmpName.length > 1)
        currTmpLink = tmpName[tmpName.length - 1];
      if (!internLinkMap.has(currTmpLink))
        internLinkMap.set(currTmpLink, true);
      siteUrl = _getSiteUrl(staticPageInfo.links.url);
      if (typeof staticPageInfo.imageSources.imageURL !== "undefined" && staticPageInfo.imageSources.imageURL !== "") {
        if (staticPageInfo.imageSources.imageURL.indexOf("/_api/v2.1/") !== -1) {
          currImgSrc = _getImageSrc(staticPageInfo.links.url);

        } else {
          currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageURL);
        }
        if (currImgSrc !== null)
          imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;
      }


    }
    else if (typeof staticPageInfo.links.linkUrl !== "undefined" && staticPageInfo.links.linkUrl !== "") {

      var tmpLink = staticPageInfo.links.linkUrl.split("/").map((item: string) => item.trim());
      var currLink: any = null;
      if (tmpLink.length > 1)
        currLink = tmpLink[tmpLink.length - 1];
      siteUrl = _getSiteUrl(staticPageInfo.links.linkUrl);
      if (typeof staticPageInfo.imageSources.imageSource !== "undefined") {
        if (staticPageInfo.imageSources.imageSource.indexOf("/_api/v2.1/") !== -1) {
          currImgSrc = _getImageSrc(staticPageInfo.links.url);
        } else {
          currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageSource);
        }
        if (currImgSrc !== null) {
          imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;

        } else {
          if (!internLinkMap.has(currLink))
            internLinkMap.set(currLink, true);
        }

      } else if (staticPageInfo.searchablePlainTexts.label) {
        Layout = "LabelButton";
        if (!internLinkMap.has(currLink))
          internLinkMap.set(currLink, true);
        ////console.log(staticPageInfo.searchablePlainTexts.label);
      }

    }

    else if (typeof staticPageInfo.links !== "undefined" && typeof (staticPageInfo.imageSources !== "undefined") && typeof (staticPageInfo.imageSource !== "undefined")) {
      if (staticPageInfo.imageSources.imageSource === undefined) {
        const obj = staticPageInfo.imageSources;
        let k: keyof typeof obj;
        for (k in obj) {
          Layout = "lightbox";
          const v = obj[k];  // OK
          ////console.log(v);
          let currImage: any = _getImgUrl(v);
          let mtpGaleryImage = `${process.env.PUBLIC_URL}/assets/News/${currImage}`;
          galeryImageList.push({ src: mtpGaleryImage, alt: currImage, width: 4, height: 3 });
          //galeryImage.push(<img className="image" src={mtpGaleryImage} alt=""/>); 
          ////console.log(galeryImageList);
        }
        ////console.log(currGalIMages);
      } else {

        ////console.log(staticPageInfo.imageSources.imageSource);
        currImgSrc = _getImgUrl(staticPageInfo.imageSources.imageSource);
        if (currImgSrc !== null) {
          ////console.log(currImgSrc);
          if (registerSeite && currImgSrc === "20828-registerformplaceholder.png") {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            registerArea = true;
            imageHtml = <img src={placeHolder} alt="placeholder" />;
            Layout = "justImage";
          } else {
            imageHtml = `${process.env.PUBLIC_URL}/assets/News/${currImgSrc}`;
            Layout = "justImage";
          }
        } else {
          Layout = "none"
        }
      }
    }
    if (typeof staticPageInfo.searchablePlainTexts.title !== "undefined" && staticPageInfo.searchablePlainTexts.title !== "")
      title = staticPageInfo.searchablePlainTexts.title;
    if (typeof staticPageInfo.searchablePlainTexts.description !== "undefined" && staticPageInfo.searchablePlainTexts.description !== "")
      descr = staticPageInfo.searchablePlainTexts.description;
    if (typeof staticPageInfo.searchablePlainTexts.label !== "undefined" && staticPageInfo.searchablePlainTexts.label !== "")
      labelTxt = staticPageInfo.searchablePlainTexts.label;
    if (Layout === "kachel") {
      console.log("siteUrl",siteUrl);
      var isImage:boolean=false;
      if(siteUrl.indexOf(".aspx"))
      {
        isImage=true;
      }
      currStaticpageItem.push(
        <div className='static-tile-box'>
          <a className="news-link" href={siteUrl}>
            <div className="static-tile-img" style={{textAlign:_align!==null&&isImage?_align:'inherit'}}>
            {isImage?
              <img src={imageHtml} alt={imageHtml} />:
              <Document file={siteUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={1} width={360} height={450}  pageIndex={1}/>
              </Document>
            }
             
               {/* <IFrame url={siteUrl}
                styles={{overflow:"hidden"}}
                id="WVIBPDFViewer"
                className='WVIBPDFViewer'
                width="350px"
                height="420px"
                allowFullScreen={false}
                scrolling="no"
                overflow="hidden"
                />  */}
              {/* <img src={imageHtml} alt={imageHtml} /> */}
            </div>
          </a>
        </div>
      );
    } else if (Layout === "justImage") {
      if (registerArea) {
        //currStaticpageItem.push(<div className="resgisterplaceholder">{imageHtml}</div>);
        currStaticpageItem.push(<Registrierung userNummer="" userData={uData} />);
      }
      else {
        ////console.log("imageHtml: ", imageHtml);
        currStaticpageItem.push(
          <div className='static-tile-box'>
            <div className="static-tile-justImg" style={{textAlign:_align!==null?_align:'inherit'}}>
              <img src={imageHtml} alt={imageHtml} />
              <div hidden={imgTile == null} className="img-Title">{imgTile!==null?parse(imgTile):null}</div>
            </div>
          </div>
        );
      }
    } else if (Layout === "lightbox") {

      currStaticpageItem.push(<div className="lightbox">
        <div className="row">
          <Gallery photos={galeryImageList} onClick={openLightbox} />
          <ModalGateway >
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={galeryImageList.map((x: { src: any; }) => ({
                    ...x,
                    source: x.src,
                    caption: '',
                    alt: ''
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div></div>);
    }
    else if (Layout === "LabelButton") {
      currStaticpageItem.push(<div className='static-tile-box'>
        <a className="news-link" href={siteUrl}>
          <button className="labelButton">{labelTxt}</button>
        </a>
      </div>
      );
    }
    else if (Layout !== "none") {
      let CurrimageHtml: any = null;
      if (imageHtml != null) {
        CurrimageHtml = <div><div className="image-static-Box-list" onClick={(e) => window.open(siteUrl, "_self")}>
          <img className="news-image" src={imageHtml} alt={imageHtml} />
        </div></div>;
      }
      currStaticpageItem.push(<div>
        <div className='post-static-Item'>
          {CurrimageHtml}
          <div className='post-static-content'>
            <div className='post-static-title'><a className="news-link" href={siteUrl}>{title!==undefined&&title!==null?parse(title):null}</a></div>
            <div className='post-static-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
            {/* <div className='link-box'>{curDate}
                  </div> */}
          </div>
        </div>
      </div>);
    }
    return currStaticpageItem;
  }
  function _getFileViewer(fileUrl:any){
    let docUrl = _getSiteUrl(fileUrl);
    return <a className="news-link" href={docUrl}>
            <div className="static-tile-img">
             <Document file={docUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={1} width={360} height={450}  pageIndex={1}/>
            </Document>
			</div>
			</a>
  }
  function _getNewsHtml(newsSchlagWort: any, layout: any,description:any) {
    let newsHtml: any = null;
    let newsShowAll: any = null;
    let newsList: any[] = [];
    let newsLeftTmp: any[] = [];
    let newsRightTmp: any[] = [];
    let newsShowAllTmp: any[] = [];
    let result: any[] = [];
    let allResult: any[] = [];
    let showAllLink: any = null;

    let newsTmp = new Map();
    let truncate = (str: string, max: number, suffix: string | any[]) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    if (newsData != null && newsData.length > 0) {

      // if(layout!=="" && layout==="Kachel")
      // {
      for (const item of newsData) {
        item.element.term.forEach((e1: any) => {
          if (e1.termName != null && e1.termName.length > 0 && (e1.termName===newsSchlagWort)) {
            if (!newsTmp.has(item.element.id)) {
              newsTmp.set(item.element.id, true);
              let blogUrl = "/information/" + encodeURIComponent(item.element.urltitle);
              // let currImgSrc = "https://spoprod-a.akamaihd.net/files/sp-client/sleekTemplateImageTile_1beada90c670860d97a36422a258917a.jpg";
              let currImgSrc = `${process.env.PUBLIC_URL}/assets/News/sitepagethumbnail.png`;
              if (item.element.imgSrc != null) {
                currImgSrc = `${process.env.PUBLIC_URL}/assets/News/${item.element.imgSrc}`;
              } else {
                currImgSrc = "sitepagethumbnail.png";
              }
              newsList.push({
                id: item.element.id,
                title: item.element.title,
                category: item.element.category,
                description: trunCat(item.element.description, 150),
                blogUrl: blogUrl,
                imgSrc: currImgSrc,
                publishedDate: new Date(item.element.publishedDate)
              });
            }
          }
        });
      }
      if (newsList.length > 1) {
        if (layout !== "" && layout === "Kachel") {
          result = newsList.sort((a, b) => b.publishedDate - a.publishedDate);
          if (newsList.length > 4)
            result = newsList.slice(0, 4);
          if (newsList.length > 4) {
            showAllLink = <p className="anzeigen-box" hidden={showAll} onClick={(e) => setShowAll(!showAll)}>Alle anzeigen</p>;
            allResult = newsList.sort((a, b) => b.publishedDate - a.publishedDate);
            allResult.forEach((element, index) => {
              let imgSrc = element.imgSrc;
              let imageHtml: any = null;
              var descr = "";
              var curDate = element.publishedDate;
              if (curDate != null && curDate !== "") {
                var newDate = convertUTCDateToLocalDate(curDate);
                curDate = moment(newDate).locale('de').format("LL");
              }
              if (imgSrc !== null && (typeof imgSrc !== "undefined") && imgSrc.indexOf("sitepagethumbnail.png") === -1) {
                imageHtml = <div className="image-static-Box-list" onClick={(e) => window.open(element.blogUrl, "_self")}>
                  {/* <img src={imgSrc} alt={element.title} className="static-news-img-list" /> */}
                  <img src={imgSrc} alt={element.title} className="news-image" />
                </div>;
              }
              if (element.description != null && element.description !== "")
                descr = truncate(element.description, 200, '...');
              newsShowAllTmp.push(
                <div className='post-static-Item'>
                  {imageHtml}
                  <div className='post-static-content'>
                    <div className='post-static-title'><a className="news-link" href={element.blogUrl}>{element.title!==undefined&&element.title!==null?parse(element.title):null}</a></div>
                    <div className='post-static-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
                    <div className='link-box'>{curDate}
                    </div>
                  </div>
                </div>
              );
            });
            newsShowAll = <div className="news-static-list" hidden={!showAll}><div className="col-lg-12 contact-group"><h4>{description}</h4></div>{newsShowAllTmp}</div>;
          }

          result.forEach((element, index) => {
            let imgSrc = element.imgSrc;
            var descr = "";
            let imageHtml: any = null;
            var curDate = element.publishedDate;
            if (curDate != null && curDate !== "") {
              var newDate = convertUTCDateToLocalDate(curDate);
              curDate = moment(newDate).locale('de').format("LL");
            }
            if (imgSrc !== null && (typeof imgSrc !== "undefined") && imgSrc.indexOf("sitepagethumbnail.png") === -1) {
              imageHtml = <div className="image-static-Box">
                <img src={imgSrc} onClick={(e) => window.open(element.blogUrl, "_self")} alt={element.title} className="static-news-img" />
              </div>;
            }
            if (element.description != null && element.description !== "")
              descr = truncate(element.description, 200, '...');
            if (index === 0) {
              newsLeftTmp.push(
                <div className="col-sm-12 col-lg-6 left-news-static" key={index}>
                  {imageHtml}
                  <div className='post-static-content news-static-content'>
                    <div className='news-post-static-title'><a className="news-link" href={element.blogUrl}>{element.title!==undefined&&element.title!==null?parse(element.title):null}</a></div>
                    <div className='post-static-small-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
                    <div className='link-box'>{curDate}
                    </div>
                  </div>
                </div>
              );
            } else {

              if (imgSrc !== null && (typeof imgSrc !== "undefined") && imgSrc.indexOf("sitepagethumbnail.png") === -1) {
                imageHtml = <div className="image-static-Box-right col-md-12 col-lg-4">
                  <img src={imgSrc} onClick={(e) => { window.open(element.blogUrl, "_self") }} alt={element.title} className="static-news-img-list-kachel" />
                </div>;
              }
              if (element.description != null && element.description !== "")
                descr = truncate(element.description, 200, '...');
              newsRightTmp.push(<div className="right-item-box col-md-12 col-lg-12" key={index}>
                {imageHtml}
                <div className='post-static-content col-md-12 col-lg-8'>
                  <div className='news-post-static-title'><a className="news-small-link" href={element.blogUrl}>{element.title!==undefined&&element.title!==null?parse(element.title):null}</a></div>
                  <div className='post-static-small-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
                  <div className='link-box'>{curDate}
                  </div>
                </div>
              </div>);
            }
          });
          newsHtml = <div className="news-outer-box"><div className="col-lg-12 contact-group"><h4>{description}</h4></div>{showAllLink}{newsShowAll}<div className="news-static-Box" hidden={showAll}>{newsLeftTmp}<div className="col-sm-12 col-lg-6">{newsRightTmp}</div></div>{showAllLink}</div>;
        }
        else {
          result = newsList.sort((a, b) => b.publishedDate - a.publishedDate);
          result.forEach((element, index) => {
            let imgSrc = element.imgSrc;
            let imageHtml: any = null;
            var descr = "";
            var curDate = element.publishedDate;
            if (curDate != null && curDate !== "") {
              var newDate = convertUTCDateToLocalDate(curDate);
              curDate = moment(newDate).locale('de').format("LL");
            }
            if (imgSrc !== null && (typeof imgSrc !== "undefined") && imgSrc.indexOf("sitepagethumbnail.png") === -1) {
              imageHtml = <div className="image-static-Box-list" onClick={(e) => window.open(element.blogUrl, "_self")}>
                {/* <img src={imgSrc} alt={element.title} className="static-news-img-list" /> */}
                <img src={imgSrc} alt={element.title} className="news-image" />
              </div>;
            }
            if (element.description != null && element.description !== "")
              descr = truncate(element.description, 200, '...');
            newsLeftTmp.push(
              <div className='post-static-Item'>
                {imageHtml}
                <div className='post-static-content'>
                  <div className='post-static-title'><a className="news-link" href={element.blogUrl}>{element.title!==undefined&&element.title!==null?parse(element.title):null}</a></div>
                  <div className='post-static-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
                  <div className='link-box'>{curDate}
                  </div>
                </div>
              </div>
            );
          });
          newsHtml = <div className="news-static-list"><div className="col-lg-12 contact-group"><h4>{description}</h4></div>{newsLeftTmp}</div>;
        }
      } else if (newsList.length === 1) {
        let imgSrc = newsList[0].imgSrc;
        let imageHtml: any = null;
        var descr = "";
        var curDate = newsList[0].publishedDate;
        if (curDate != null && curDate !== "") {
          curDate = moment(newsList[0].publishedDate).locale('de').format('LL');
        }
        if (imgSrc !== null && (typeof imgSrc !== "undefined") && imgSrc.indexOf("sitepagethumbnail.png") === -1) {
          imageHtml = <div className="image-static-Box-list" onClick={(e) => window.open(newsList[0].blogUrl, "_self")}>
            {/* <img src={imgSrc} alt={element.title} className="static-news-img-list" /> */}
            <img src={imgSrc} alt={newsList[0].title} className="news-image" />
          </div>;
        }
        if (newsList[0].description != null && newsList[0].description !== "")
          descr = truncate(newsList[0].description, 200, '...');
        newsLeftTmp.push(
          <div className='post-static-Item'>
            {imageHtml}
            <div className='post-static-content'>
              <div className='post-static-title'><a className="news-link" href={newsList[0].blogUrl}>{newsList[0].title!==undefined&&newsList[0].title!==null?parse(newsList[0].title):null}</a></div>
              <div className='post-static-desc'>{descr!==undefined&&descr!==null?parse(descr):null}</div>
              <div className='link-box'>{curDate}
              </div>
            </div>
          </div>
        );
        newsHtml = <div className="news-static-list"><div className="col-lg-12 contact-group"><h4>{description}</h4></div>{newsLeftTmp}</div>;
      }

    }
    return newsHtml;
  }
  function _getContactHtml(contactString: any) {
    let contactHtml: any = null;
    var json = JSON.parse(contactString);
    let contactResult: any[] = [];
    let contactTitle: any = null;
    if ((typeof json !== "undefined") && json !== null) {

      let contactFields = json.properties.fields;
      let columnContact = "contact-col-lg-3 col-lg-3";
      let tmpArray: any[] = [];
      let sortArray: any[] = [];
      let _contactsMap = new Map();
      if (registerSeite)
        columnContact = "col-lg-12";
      // eslint-disable-next-line valid-typeof
      if ((typeof contactFields !== "undefined") && contactFields.length > 0 && contatcsData !== null && contatcsData.length > 0) {
        contactFields.forEach((e1: any) => {
          contatcsData.forEach((e2: any) => {
            if (e1 === Number(e2.id)) {
              let orderId = 1;
              let _currRank: string = e2.ranking;
              if (_currRank != null && _currRank.length > 0)
                orderId = _currRank.length
              let currNachname = e2.nachname;
              if (!_contactsMap.has(e2.id)) {
                _contactsMap.set(e2.id, true);
                tmpArray.push({ id: orderId, name: currNachname, item: e2 });
              }
            }
          });
        });
        sortArray = tmpArray.sort(function (a, b) {
          if (a.id === b.id) {
            a = a.name || '';
            b = b.name || '';
            return a.localeCompare(b, 'de-DE')
          }
          return a.id - b.id
        });
        //console.log("tmp:", tmpArray);
        //console.log("sort", sortArray);
        sortArray.forEach((e2: any) => {
          let currEmail = "mailto:" + e2.item.email + "?subject=Kontakt";
          contactResult.push(
            <div key={e2.item.id} id={e2.item.id} style={{ order: e2.id }} className={columnContact}  >
              <div className="contact-box row">
                <div className="contact-box-col col-md-3">
                  <div className="imageArea-85">
                    <div className="image-134">
                      <img className="image-135" src={`${process.env.PUBLIC_URL}/assets/Kontakte/${e2.item.imgSrc}`} alt={e2.item.name} />
                    </div>
                  </div>
                </div>
                <div className="text-box col-md-8">
                  <div className="name-box">{e2.item.name}</div>
                  {/* <div className="desc-box">{e2.Unternehmen}</div>*/}
                  <div className="desc-box">{e2.item.position}</div>
                  <div className="desc-box"><a href={currEmail} style={{ visibility: e2.item.email ? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-email.svg`} style={{ visibility: e2.item.email ? 'visible' : 'hidden' }} className="icon-box" alt="email" /></a><div className="phone-box" style={{ visibility: e2.item.telefon ? 'visible' : 'hidden' }}><img src={`${process.env.PUBLIC_URL}/assets/images/ic24-phone.svg`} className="icon-box" alt="phone" />{e2.item.telefon}</div></div>
                </div>
              </div>
            </div>
          );
        })
        contactTitle = json.properties.description;
        contactHtml = <div className="row contact-box"><div className="col-lg-12 contact-group"><h4>{contactTitle}</h4></div>{contactResult}</div>;
      }


    }
    return contactHtml;
  }
  function convertUTCDateToLocalDate(date: any) {
    //  var newDate=date.toISOString();
    //  //console.log(date.toISOString());
    //var offset = moment().utcOffset();
    // if(date!==undefined && date.indexOf('GMT')!==-1){
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    // }else{
    //  newDate = moment.utc(date).utcOffset(offset);
    // }
    return newDate;
  }
  function _getImgUrl(imgUrl: any) {

    let imageName: any = null;
    if (typeof imgUrl != "undefined") {
      var exst = imgUrl.split("/").map((item: string) => item.trim());
      // var exst = imgUrl.toString().split(/[\s/]+/);
      if (exst != null && exst.length > 0) {
        imageName = exst[exst.length - 1]
      }
    }
    return imageName;

  }
  function _getInternLink(imgUrl: any) {
    let paegUrlFullName = "";
    let pageUrl = imgUrl;
    if (imgUrl !== null && imgUrl.indexOf("sites/WVIB_Akademie#/") > 0) {
      let _tmpUrl = imgUrl.split('#');
      // pageUrl = "/AnonEvents.html#" + _tmpUrl[1];
      pageUrl = "/Seminarfinder" + _tmpUrl[1];
    }
    else if (imgUrl !== null && imgUrl !== "" && imgUrl.indexOf("sites/WVIBWeb/") > 0) {
      var file = imgUrl.split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        paegUrlFullName = file[file.length - 1];
        if (exst[exst.length - 1] === "aspx") {
          pageUrl = "/Pages/" + _createurltitle(paegUrlFullName);
        }
      }
    }
    return pageUrl;
  }
  function _createurltitle(_title:any){
    const pattern: RegExp = /[^0-9a-zA-Z.]/g;
    var tmpTtile= decodeURIComponent(_title);
    tmpTtile= tmpTtile.replace(pattern, "");
    return tmpTtile;
  }
  function _getQuickLinks(_Url: any, title: any, quickLinksTitle: any) {
    let currentICon: any;
    let docHtml: any = null;
    let fileName = "";
    let fileFullName = "";
    if (_Url != null && _Url !== "" && _Url.indexOf("sites/WVIBWeb/") !== 0) {
      var file = _Url.toString().split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        fileFullName = file[file.length - 1];

        fileName = exst[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (exst != null && exst.length > 0) {
          switch (exst[exst.length - 1]) {
            case 'pdf':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pdf.svg`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'docx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_docx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'xlsx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_xslx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
            case 'pptx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
              break;
              case 'potx':
                currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
                docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{title}</a></div>
                break;
            case 'aspx':
              var siteName = fileName.split("/").map((item: string) => item.trim());
              let pageUrl = "/Pages/" + _createurltitle(fileFullName);
              if (siteName.length > 1)
                fileName = siteName[siteName.length - 1]
              docHtml = <div className="doc-box">{currentICon}<a className="intern-link" href={pageUrl}>{title}</a></div>;
              break;
            default:
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/ic24-download.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/News/${fileFullName}`}>{title}</a></div>;
              break;
          }
        }
      }
    }
    return docHtml;

  }
  function _getFileHtml(imgUrl: any) {
    let currentICon: any;
    let docHtml: any = null;
    let fileName = "";
    let fileFullName = "";
    if (imgUrl != null && imgUrl !== "" && imgUrl.indexOf("sites/WVIBWeb/") !== 0) {
      var file = imgUrl.toString().split("/").map((item: string) => item.trim());
      var exst = file[file.length - 1].toString().split(".").map((item: string) => item.trim());
      if (exst.length > 1) {
        fileFullName = file[file.length - 1];

        fileName = exst[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (exst != null && exst.length > 0) {
          switch (exst[exst.length - 1]) {
            case 'pdf':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pdf.svg`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{{ fileName }}</a></div>
              break;
            case 'docx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_docx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{{ fileName }}</a></div>
              break;
            case 'xlsx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_xslx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{{ fileName }}</a></div>
              break;
            case 'pptx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{{ fileName }}</a></div>
              break;
              case 'potx':
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/wvib_pptx.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/dokumente/${fileFullName}`}>{{ fileName }}</a></div>
              break;
            case 'aspx':
              var siteName = fileName.split("/").map((item: string) => item.trim());
              let pageUrl = "/Pages/" + _createurltitle(fileFullName);
              if (siteName.length > 1)
                fileName = siteName[siteName.length - 1]
              docHtml = <div className="doc-box">{currentICon}<a className="intern-link" href={pageUrl}>{fileName}</a></div>;
              break;
            default:
              currentICon = <img className="doc-icon" src={`${process.env.PUBLIC_URL}/assets/images/ic24-download.png`} alt="" />;
              docHtml = <div className="doc-box">{currentICon}<a href={`${process.env.PUBLIC_URL}/assets/News/${fileFullName}`}>{fileName}</a></div>;
              break;
          }
        }
      }
    }
    return docHtml;

  }

  //#endregion
}



