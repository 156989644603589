import axios from 'axios';
// import qs from 'qs';
import { PnPClientStorage } from "@pnp/common";
//PROD
const APP_ID = 'a86c7422-c0c9-4b83-ab15-cbcbee644b5f';
const APP_SECERET = 'kb-r5XA9r6uVaqpe8xJO~_7B11-5JDc_s0';
//TEST
// const APP_ID = '375ea6b4-d46d-40df-aff2-7931f5eaf5a3';
// const APP_SECERET = 'jyS2PwPK8OdgVG5N1e_1Q1j~Ni5-h-Ufb5';
const TOKEN_ENDPOINT = 'https://g.microsoftonline.com/8aa07f6a-735f-49f8-80f5-95eaf445c393/oauth2/v2.0/token';
// const MS_GRAPH_SCOPE = 'https://wvib.de/wvibpublicwebtest/.default';
const FUNCTIONCODE = 'zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==';
//const qs = require('qs');
export default class AzureConnector {
    private storageContacts: string = 'storageContacts';
//PROD
    private azureFuncUrl: string = 'https://wvibpublicwebprod.azurewebsites.net/api';
//TEST
    // private azureFuncUrl: string = 'https://wvibpublicwebtest.azurewebsites.net/api';
    // private postData = {
    //   client_id: APP_ID,
    //   scope: MS_GRAPH_SCOPE,
    //   client_secret: APP_SECERET,
    //   grant_type: "client_credentials"
    // };
    public azureInstance: any;

    public get(endpoint: string): Promise<void>{
      return new Promise<void>((resolve: (result: any) => void, reject: (error: any) => void): void => {
        //#region Get Data Section
        let config = {
          headers: {
            Accept: "application/json, text/plain, */*",
            'Content-Type': "application/json; charset=utf-8",
            Connection: "keep-alive",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
          }
        };

        let azureFuncUrl = this.azureFuncUrl;
        if(endpoint.indexOf("/sp/") > -1 )
        {
          azureFuncUrl = azureFuncUrl.replace("/nav", "");
        }
        let currAzureFuncUrl = azureFuncUrl + endpoint;
        axios.get(currAzureFuncUrl, config).then((res: any): void => {
          if(res.status === 200 && res.data && res.data.length === undefined)
          {
            resolve(res.data);
          }
          else if (res.data.length > 0) {
            resolve(res.data);
          } else {
            reject("no data");
          }
        })
        .catch((error: any) => {
          console.log(error);
          reject(error);
        });
        //#endregion
      });
    }

    public post(endpoint: string, data: any): Promise<void>{
      return new Promise<void>((resolve: (result: any) => void, reject: (error: any) => void): void => {
        //#region Get Data Section
        let config = {
          headers: {
            Accept: "application/json, text/plain, */*",
            'Content-Type': "application/json; charset=utf-8",
            Connection: "keep-alive",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
          }
        };

        //TODO: uncommit by prod. 
          let currAzureFuncUrl = this.azureFuncUrl + endpoint;
       // let currAzureFuncUrl = "https://wvibpublicwebtest.azurewebsites.net/api/nav/registrations?code=XnYiCjfXAr3SLVtUDaFen/k5UYolbU/DXS5BHfj83KkihsNroRwopA==&clientId=nav_publicweb";

        axios.post(currAzureFuncUrl, data, config).then((res: any): void => {
          if(res.status === 200 && res.data && res.data.length === undefined)
          {
            resolve(res.data);
          }
          else if (res.data.length > 0) {
            resolve(res.data);
          }
          else if(res.status === 200)
          {
            resolve("Ok");
          }
          else {
            reject("no data");
          }
        })
        .catch((error: any) => {
            console.log(error);
            reject(error);
        });
        //#endregion
    });
  }

}
